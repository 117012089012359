import axios from "axios";
import {Network} from "@capacitor/core";
import {store} from "@/store";
import {StorageService} from "@/services/storage.service";

const ApiService = {
    locale: process.env.locale || 'da',
    hasNetwork: false,
    async isNetworkAvailable(){
       const network = await Network.getStatus();
       // console.log(network);
       this.hasNetwork = network.connected;
       return this.hasNetwork;
    },
    init (baseURL: string | undefined){
    axios.defaults.baseURL = baseURL;
    },
    setHeader() {
    // const token = TokenService.getToken();
    // if(token) {
    //     axios.defaults.headers.common[
    //         "Authorization"
    //         ] = 'Bearer ' + token;
    // }
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        },
    removeHeader(){
    axios.defaults.headers.commen = {};
    },
    async generateShareLink(url: string){
        const language = await StorageService.getObject('selected_language');
        const visibleMarkers = await store.getters['markers/SelectedMarkers'];
        const selectedPaths = await store.getters['paths/SelectedPaths'];
            const selectedPathTypes = await store.getters['paths/SelectedPathTypes'];
            const selectedCategories = await store.getters['categories/SelectedCategories'];
            const selectedSubCategories = await store.getters['categories/SelectedSubCategories'];
        const result: any = await axios.post('generatesharelink', {
            url: url,
            language: JSON.stringify(language),
            visibleMarkers: visibleMarkers,
            selectedPaths: selectedPaths,
            selectedPathTypes: selectedPathTypes,
            selectedCategories: selectedCategories,
            selectedSubCategories: selectedSubCategories,
        });
        // console.log('generateShareLink',[result.data]);
        return result.data

    },
    async getSharedInfoUrl(link: string) {
        return await axios.get('getsharedinfourl/' + link).then((result) => {
            return result.data.url;
        })
    },
    async getSharedInfo(link: string) {
        const result: any = await axios.get('getsharedinfo/' + link);
        const sharedInfo = result.data;
        console.log('getsharedinfo', sharedInfo);
        if (sharedInfo.language) {
            // console.log('defaults/setLanguage',JSON.parse(sharedInfo.language));
            store.dispatch('defaults/setLanguage', JSON.parse(sharedInfo.language));
        }
        if (sharedInfo.visibleMarkers && sharedInfo.visibleMarkers.length > 0) {
            store.dispatch('markers/setSelectedMarkers', sharedInfo.visibleMarkers);
        }
        if (sharedInfo.selectedPaths && sharedInfo.selectedPaths.length > 0) {
            store.dispatch('paths/setSelectedPaths', sharedInfo.selectedPaths)
        }
        if (sharedInfo.selectedPathTypes && sharedInfo.selectedPathTypes.length > 0) {
            store.dispatch('paths/setSelectedPathTypes', sharedInfo.selectedPathTypes);
        }
        if (sharedInfo.selectedCategories && sharedInfo.selectedCategories.length > 0) {
            store.dispatch('categories/setSelectedCategories', sharedInfo.selectedCategories);
        }
        if(sharedInfo.selectedSubCategories && sharedInfo.selectedSubCategories.length > 0){
            store.dispatch('categories/setSelectedSubCategories',sharedInfo.selectedSubCategories);
        }
        return result.data
    },
    async dashboardInfo(){
        const result: any = await axios.get('frontPage/'+this.locale)
        console.log(result.data)
        return result.data

    },
    async getAllMarkers(){
      const result: any = await axios.get('markers/'+this.locale);
          // console.log('markers',result);
          // // console.log(result.data);
          return result.data
    },
  async  getAllPathTypes(){
const result: any = await axios.get('pathtypes/'+this.locale);
          // console.log('pathtypes',result);
          // // console.log(result.data);
          return result.data
    },
  async  getAllCategories(){
const result: any = await axios.get('categories/'+this.locale);
          console.log('categories',result);
          // // console.log(result.data);
          return result.data
    },
    get(resource: string){
    this.setHeader();
    return axios.get(resource);
    },
    async post(resource: string,data: any){
        this.setHeader();
        const result: any = await axios.post(resource,data);
        // console.log('axios post',result);
        return result;
    },
    put(resource: string, data: any){
    return axios.put(resource,data);
    },
    delete(resource: string){
        return axios.delete(resource);
    },
async customRequest(data: any){
    return await axios(data);
    },
    monitorNetworkChange(){
        Network.addListener('networkStatusChange', (status) => {
            // console.log("Network status changed", status);
            this.hasNetwork = status.connected;
        });
},
setLocale(locale: string){
        this.locale = locale || 'da';
}
}
export default ApiService;
