<template>
    <slot></slot>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Popover'
  });
</script>
