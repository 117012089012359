
import {IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonPage, IonSearchbar, IonToggle, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import {chevronBackOutline, chevronDownOutline, chevronForwardOutline} from 'ionicons/icons';
import {mapActions, mapGetters} from "vuex";
import {store} from "@/store";
import {useRoute} from "vue-router";
import Footer from "@/components/Footer.vue";
// import LanguageMenu from "@/components/LanguageMenu.vue";
import Refresher from "@/components/Refresher.vue";

export default defineComponent({
    name: "LoadedPathType",
    components: {
        Refresher,
        // LanguageMenu,
        Footer,
        IonPage,
        IonContent,
        IonItem,
        IonLabel, IonIcon,
        IonItemDivider,
            IonHeader,
            IonButtons,
            IonButton,
            IonToggle,
            IonToolbar,
            IonCard,
            IonCardHeader,
            IonCardTitle,
            IonCardContent,
            IonSearchbar
        },
        data(){
            const searchInput = '';
            return {
                searchInput,
            }
        },
        setup() {
            const route = useRoute();
            return {
                route,
                chevronBackOutline,
                chevronForwardOutline,
                chevronDownOutline
}
        },
        computed: {
            ...mapGetters({
                isPathTypeSelected: 'paths/isPathTypeSelected',
                isPathSelected: 'paths/isPathSelected',
                isDrawerSmall: "drawer/isDrawerSmall",

                isDrawerFull: "drawer/isDrawerFull",

            }),
            isDrawerActive() {
                return window.innerWidth < 850;
            },
            loadedPathType() {
                const pathtypeId: any = this.route.params.pathtypeId;
                return store.getters['paths/PathType'](pathtypeId);
            },
            loadedPaths() {
                // console.log('input',[this.searchInput]);
                const pathtypeId: any = this.route.params.pathtypeId;
                const paths = store.getters['paths/Paths'](pathtypeId);
                if (this.searchInput != '') {
                    return paths.filter((path: any) => {
                        return path.name.toLowerCase().match(this.searchInput.toLowerCase());
                    });
                }
                return paths;
            }
        },
        methods: {
            ...mapActions({
                togglePath: 'paths/togglePath',
                togglePathType: 'paths/togglePathType'
            }),
            imagePath(image: any) {
                return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
            },
            goToAndZoomIn(path: any, url: string) {
                store.dispatch('paths/zoomToPath', path);
                this.$router.push(url);
            }
        },
    });
