
import {
    getPlatforms,
    IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonPopover, IonRow, IonTitle, toastController
} from '@ionic/vue';

import {
    shareSocialOutline,
    mailOutline,
    logoFacebook,
    logoWhatsapp,
    logoTwitter,
    logoInstagram,
    logoPinterest,
    copyOutline,
    checkmarkOutline,
    planetOutline
} from 'ionicons/icons';
import {defineComponent, ref} from 'vue';
import Popover from "@/components/Popover.vue";
import { SocialSharing } from '@ionic-native/social-sharing';
import ApiService from "@/services/api.service";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "SocialShare",
    props:['text','title','images'],
    components: {
        IonButton,
        IonPopover,
        Popover,
        IonContent,
        IonRow,
        IonCol,
        IonImg,
        IonIcon,
        IonGrid,
        IonTitle

    },
    data(){
        const shareLink = '';
        const socialSharing = SocialSharing
        return {
            socialSharing,
            shareLink,
        }
    },
    setup() {
        const t = useI18n();
        const isOpenRef = ref(false);
        const copied = ref(false);
                    const route = useRoute();

        return {
                            route,
            t,
            isOpenRef,
            shareSocialOutline,
            mailOutline,
            logoFacebook,
            logoWhatsapp,
            logoTwitter,
            logoInstagram,
            logoPinterest,
            copyOutline,
            checkmarkOutline,
            planetOutline,
            copied
        }
    },
    methods: {
        setOpen(state: boolean){
            if (state) {
                ApiService.generateShareLink(this.route.path).then((result) => {
                    if (result.success) {
                        this.shareLink = result.sharelink;
                        this.isOpenRef = state;
                        // console.log(state);
                    }else{
                        this.openToast(this.$t('ANERRORHASOCCURED'));
                    }
                })
            } else {
                this.isOpenRef = state;
            }
        },
copyPaste(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.shareLink;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => {
        this.copied = false
    },2000)
},
        isPlatform(questions: any){
            let answer = false;
            questions.forEach((question: any) => {
                if(getPlatforms().includes(question)){
                    answer = true;
                }
            })
            return answer;
        },
        Share(type: any){
            switch (type){
                case 'Link':
                    this.ShareLink()
                    break;
                case 'Email':
                    this.SendEmail();
                    break;
                case 'Facebook':
                    this.ShareFacebook();
                    break;
                case 'Whatsapp':
                    this.ShareWhatsapp();
                    break;
                case 'Twitter':
                    this.SendTwitter();
                    break;
                case 'Instagram':
                    this.SendInstagram();
                    break;
            }
        },
        ShareLink(){
            this.socialSharing.share(this.text+'\n', this.title, '', this.shareLink)
        },
        SendEmail(){
            this.socialSharing.canShareViaEmail().then(() => {
                this.socialSharing.shareViaEmail(this.text, this.title, ['email@address.com'], [''], [''], this.firstImageUrl).then(() => {
                    this.setOpen(false);
                })
            }).catch(() => {
alert('Sorry, there was some trouble with sharing via email');
            });
        },
        ShareFacebook(){
            // window.plugins.socialsharing.shareViaFacebook(this.text, this.firstImageUrl, this.shareLink).then(() => {
            //     this.setOpen(false);
            // })
            this.socialSharing.shareViaFacebook(this.text, this.firstImageUrl, this.shareLink).then(() => {
                this.setOpen(false);
            })
        },
        ShareWhatsapp(){
            this.socialSharing.shareViaWhatsApp(this.text, this.firstImageUrl, this.shareLink).then(() => {
                this.setOpen(false);
            })
        },
        SendTwitter(){
            this.socialSharing.shareViaTwitter(this.text, this.firstImageUrl, this.shareLink).then(() => {
                this.setOpen(false);
            })
        },
        SendInstagram(){
            this.socialSharing.shareViaInstagram(this.text, this.firstImageUrl).then(() => {
                this.setOpen(false);
            })
        },
        shareViaWebShare( ) {
            ApiService.generateShareLink(this.route.path).then((result) => {
                if (result.success) {
                    this.shareLink = result.sharelink;
                    navigator.share({
                        title: this.title,
                        text: this.text,
                        url: this.shareLink
                    })
                }
            })
        },
        async openToast(message: string, duration = 2000) {
            const toast = await toastController
                .create({
                    message: message,
                    duration: duration
                })
            return toast.present();
        }
    },
    computed:{
        firstImageUrl(): any{
            // console.log(this.images);
            if (typeof this.images === "string") {
                const image = this.images;
                const url = (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image
// console.log(url);
                return url;
            } else {
                if (this.images && this.images.length == 1 && this.images[0].FileType.includes('image')) {
                    const image = this.images[0].Uri;
                    const url = (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image
                    // console.log(url);
                    return url;
                } else {
                    if(this.images) {
                        const obj = this.images;
                        const image = obj[Object.keys(obj)[0]].Uri;
                        const url = (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image
                        // console.log(url);
                        return url;
                    }else{
                        return null;
                    }
                }
            }
        },
        webShareApiSupported() {
            return navigator.share
        }
    }
});
