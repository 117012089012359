import ApiService from "@/services/api.service";
import {store} from "@/store/index";
import {StorageService} from "@/services/storage.service";

const state = {
    SelectedMarkers: [],
    Markers: [],
    zoomToMarker: {},
    focusMarker: {},
    unfocusMarker: {}
};

const getters = {
    SelectedMarkers: (state: { SelectedMarkers: any }) => {
        return state.SelectedMarkers;
    },
    Markers: (state: { Markers: any }) => {
        return state.Markers;
    },
    Marker: (state: { Markers: any }) => {
        return (SubCategoryGuideDanmarkId: any, MarkerGuideDanmarkId: any) => {
            return state.Markers.find((mark: any) => (mark.category_id == SubCategoryGuideDanmarkId && mark.guide_danmark_id == MarkerGuideDanmarkId))  }
    },
    isMarkerSelected: (state: {SelectedMarkers: any}) => {
    return (SubCategoryGuideDanmarkId: any, MarkerGuideDanmarkId: any) => {
        if(state.SelectedMarkers && state.SelectedMarkers[SubCategoryGuideDanmarkId]) {
            return state.SelectedMarkers[SubCategoryGuideDanmarkId].includes(MarkerGuideDanmarkId);
        }else{
            return false;
        }
    };
    },
    SubCategoryCount: (state: {SelectedMarkers: any}) =>{
        return (SubCatId: any) => (state.SelectedMarkers[SubCatId] != undefined) ? state.SelectedMarkers[SubCatId].length : 0;
    },
    ZoomToMarker: (state: {zoomToMarker: any}) => {
        // console.log(state.zoomToMarker);
        return state.zoomToMarker;
},
    FocusMarker: (state: {focusMarker: any}) => {
        // console.log(state.zoomToMarker);
        return state.focusMarker;
},
    UnfocusMarker: (state: {unfocusMarker: any}) => {
        // console.log(state.unfocusMarker);
        return state.unfocusMarker;
}
};

const actions = {
    async init(context: any){
        // console.log('init markers');
        const markers = await StorageService.getObject('markers');
        if (markers && markers.length > 0) {
            context.commit('setMarkers', markers);
        }
        const data: any = await ApiService.getAllMarkers();
        if(data.success){
            context.commit('setMarkers', data.markers);
        }

    },
    async fetchVisible(context: any){
        const lastUpdated = parseInt(await StorageService.getObject('selected_markers_last_updated'));
        if(!lastUpdated || (new Date().getTime() - lastUpdated) < (process.env.VUE_APP_SELECTEDDURATION * 60 * 60 * 1000)) {
            const SelectedMarkers = await StorageService.getObject('selected_markers');
            if (SelectedMarkers && SelectedMarkers.length > 0) {
                context.commit('setSelectedMarkers', SelectedMarkers);
            }
        }
    },
    toggleMarker( {commit, state}: any, data: any) {
        if(state.SelectedMarkers && state.SelectedMarkers[data.subCategoryGuideDanmarkId] && state.SelectedMarkers[data.subCategoryGuideDanmarkId].includes(data.guideDanmarkId)){
            commit("removeFromSelectedMarkers", data);
        }else{
            commit("addToSelectedMarkers", data);
        }
    },
    addToSelectedMarkers(context: any, data: any){
        if(data.guideDanmarkId){
            context.commit("addToSelectedMarkers", data);
        }
    },
    removeFromSelectedMarkers(context: any, data: any){
        if(data.guideDanmarkId){
            context.commit("removeFromSelectedMarkers", data);
        }
    },
setMarkers(context: any, data: any){
if(data && data.markers){
    context.commit("setMarkers", data.markers);
}
},
    reset(context: any){
    context.commit('reset');
    },
    async check(state: any): Promise<any>{
        if(state.markers && state.markers.length > 0) {
           return await store.dispatch('markers/init');
        }
        else return;

    },
    zoomToMarker(context: any, data: any) {
        context.commit('zoomToMarker',data.marker);
    },
    focusMarker(context: any, marker: any) {
        context.commit('focusMarker',marker);
    },
    unfocusMarker(context: any, marker: any) {
        context.commit('unfocusMarker',marker);
    },
    setSelectedMarkers(context: any, SelectedMarkers: any) {
        if(SelectedMarkers.length > 0){
            context.commit('setSelectedMarkers',SelectedMarkers);
        }
    }

};

const mutations = {
    addToSelectedMarkers(state: {
        SelectedMarkers: any;
    }, data: any) {
        if (data.guideDanmarkId && data.subCategoryGuideDanmarkId) {
            console.log('addToSelectedMarkers', data);
            // console.log('addToSelectedMarkers',state.SelectedMarkers);
            if (!state.SelectedMarkers[data.subCategoryGuideDanmarkId]) {
                state.SelectedMarkers[data.subCategoryGuideDanmarkId] = [];
            }
            state.SelectedMarkers[data.subCategoryGuideDanmarkId].push(parseInt(data.guideDanmarkId));
            console.log('addToSelectedMarkers', state.SelectedMarkers);
            StorageService.setObject('selected_markers', state.SelectedMarkers);
        }
    },
    removeFromSelectedMarkers(state: {
        SelectedMarkers: any;
    },data: any) {
        // console.log('removeFromSelectedMarkers',state.SelectedMarkers);
        // console.log('removeFromSelectedMarkers',data);
        const index = state.SelectedMarkers[data.subCategoryGuideDanmarkId].indexOf(data.guideDanmarkId, 0);
        if (index > -1) {
            state.SelectedMarkers[data.subCategoryGuideDanmarkId].splice(index, 1);
        }
        if(state.SelectedMarkers[data.subCategoryGuideDanmarkId].length == 0){
            const index2 = state.SelectedMarkers.indexOf(data.subCategoryGuideDanmarkId, 0);
            if (index2 > -1) {
                state.SelectedMarkers.splice(index2, 1);
            }
        }
                StorageService.setObject('selected_markers', state.SelectedMarkers);

    },
    setMarkers(state: { Markers: any }, markers: any) {
        state.Markers = markers;
        StorageService.setObject('markers', state.Markers);

    },
    reset(state: {SelectedMarkers: any}){
        state.SelectedMarkers = [];
        StorageService.removeItem('selected_markers');
    },
    zoomToMarker(state: {zoomToMarker: any}, marker: any){
        state.zoomToMarker = marker;
        setTimeout(function() {
            state.zoomToMarker = {};
        }, 100);
    },
    unfocusMarker(state: {unfocusMarker: any}, marker: any){
        state.unfocusMarker = marker;
        setTimeout(function () {
            state.unfocusMarker = undefined;
        }, 500);
    },
    focusMarker(state: {focusMarker: any}, marker: any) {
        state.focusMarker = marker;
        setTimeout(function () {
            state.focusMarker = undefined;
        }, 500);
    },
    setSelectedMarkers(state: {SelectedMarkers: any}, SelectedMarkers: any){
        state.SelectedMarkers = SelectedMarkers;
        StorageService.setObject('selected_markers', state.SelectedMarkers);


    }
}

export const markers = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
