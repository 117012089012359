import { createStore } from 'vuex';
import { markers } from './markers.store';
import { dashboard } from './dashboard.store';
import { paths } from './paths.store';
import { categories } from "./categories.store";
import { drawer } from "@/store/drawer.store";
import {defaults} from "@/store/defaults.store";

export const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        dashboard,
        markers,
        paths,
        categories,
        drawer,
        defaults
    },
});
