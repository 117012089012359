// import {Plugins} from "@capacitor/core";


import { openDB } from 'idb';
const keyval = 'DestinationSoenderjylland';
const prefix = 'visitSounderjylland_';
const dbPromise = openDB(keyval + '-db', 1, {
    upgrade(db) {
        db.createObjectStore(keyval);
    },
});
//
//
// export async function get(key: any) {
//     return (await dbPromise).get(keyval, key);
// },
// export async function set(key: any, value: any) {
//     return (await dbPromise).put(keyval, value, key);
// },
// export async function del(key: any) {
//     return (await dbPromise).delete(keyval, key);
// },
// export async function clear() {
//     return (await dbPromise).clear(keyval);
// },
// export async function keys() {
//     return (await dbPromise).getAllKeys(keyval);
// },



// const {Storage} = Plugins;

export class StorageService {

    static async setString(key: string, value: string) {
       await (await dbPromise).put(keyval, value, prefix+key);

        // await Storage.set({ key, value });
    }

    static async getString(key: string) {
        return (await dbPromise).get(keyval, prefix+key);
        // return await Storage.get({ key }).then((val) =>{ return val; });
    }

    static async setObject(key: string, value: any) {
        await (await dbPromise).put(keyval, JSON.stringify(value), prefix+key);
        await (await dbPromise).put(keyval, new Date().getTime()+'',prefix+key+'_last_updated');
        // await Storage.set({ key, value: JSON.stringify(value) });
    }

    static async getObject(key: string) {
        const data = await (await dbPromise).get(keyval, prefix+key);
        // const data = await Storage.get({ key });
        if (data != undefined && data && data != 'undefined') {
            return JSON.parse(data);
        }
        return null;
    }


    static async removeItem(key: string) {
        await (await dbPromise).delete(keyval, prefix+key);
        // await Storage.remove({ key });
    }

    async clear() {
        await (await dbPromise).clear(keyval);
        // await Storage.clear();
    }

}
