<template>
    <div id="container">
        <div class="map" ref="mapDivRef"></div>
    </div>
    <div id="googlemap-categories-list-parent" :class="(isHomeLogoActive) ? 'logo-active' : 'logo-not-active'">
        <div id="googlemap-categories-list-child">
            <div v-for="(category) in categories" :key="category">
                <ion-button shape="round" color="light" size="small"
                            :router-link="'/category/'+category.guide_danmark_id" @click="openMenu('full')">
                    {{ category.name }}
                </ion-button>
                <ion-badge v-if="visibleSelectedSubcategoriesOnThisCategory(category) != 0" slot="end"
                           :style="'--background:' + (category.color) ? category.color+';' : 'var(--red)' ">
                    {{ visibleSelectedSubcategoriesOnThisCategory(category) }}
                </ion-badge>
            </div>
            <div>
                <ion-button shape="round" color="warning" size="small" @click="resetSelected()">{{
                        $t('RESET')
                    }}
                </ion-button>
            </div>
        </div>
    </div>
    <ion-grid id="googlemap-pathtype-list">
        <ion-row class="ion-align-items-center">
            <ion-col size="10" offset="1" class="ion-text-center">
                <!--            <ion-button  detail="false">-->

                <ion-thumbnail v-for="(pathType) in pathTypes" :key="pathType" :id="'helloid'+pathType.id" :router-link="'/pathtype/'+pathType.id"
                               @click="openMenu('full')">
                    <figure>
                        <ion-icon :src="imagePath(pathType.icon)"></ion-icon>

                        <figcaption class="ion-text-center">{{ pathType.name }}
                            <ion-badge :id="'badge'+pathType.id" v-if="visibleSelectedPathsOnThisPathType(pathType) != 0" slot="end"
                                       :style="'--background:' + (pathType.color) ? pathType.color+';' : 'var(--red);' ">
                                {{ visibleSelectedPathsOnThisPathType(pathType) }}
                            </ion-badge>
                        </figcaption>
                    </figure>
                </ion-thumbnail>
                <!--            </ion-button>-->
                <!--            </ion-button>-->
            </ion-col>
        </ion-row>
    </ion-grid>
    <img id="km-img" src="/assets/images/1km.png">
    <!--    </ion-split-pane>-->
</template>

<script>
import {defineComponent, onMounted, ref} from 'vue';
import {env} from "@/services/globals";
import {mapActions, mapGetters} from "vuex";
import {IonBadge, IonButton, IonCol, IonGrid, IonIcon, IonRow, IonThumbnail} from '@ionic/vue';
import {store} from "@/store";
import router from "@/router";
import ApiService from "@/services/api.service";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "GoogleMap",
    components: {
        IonButton,
        IonBadge,
        IonGrid,
        IonRow,
        IonThumbnail,
        IonIcon,
        IonCol
    },
    emits: [
        'openMenu'
    ],
    props: [
        'isHomeLogoActive'
    ],
    // mounted() {
    //     setTimeout(async function () {
    //             // store.dispatch('categories/fetchSelected');
    //             // store.dispatch('paths/fetchSelected');
    //             // store.dispatch('markers/fetchVisible');
    //             // ApiService.getSharedInfo();
    //         },
    //         1000);
    //
    //
    // },
    data() {
        const currentSelectedCategories = [];
        const currentSelectedSubCategories = [];
        const currentSelectedMarkers = [];
        const currentSelectedPathTypes = [];
        const currentSelectedPaths = [];
        return {
            currentSelectedCategories,
            currentSelectedSubCategories,
            currentSelectedMarkers,
            currentSelectedPathTypes,
            currentSelectedPaths,
        }
    },
    computed: {
        ...mapGetters({
            // makers: "markers/Markers",
            categories: "categories/Categories",
            pathTypes: "paths/PathTypes",
            selectedSubCategories: "categories/SelectedSubCategories",
            selectedCategories: "categories/SelectedCategories",
            SelectedMarkers: "markers/SelectedMarkers",
            selectedPathTypes: "paths/SelectedPathTypes",
            selectedPaths: "paths/SelectedPaths",
            isCategorySelected: "categories/isCategorySelected",
            isSubCategorySelected: "categories/isSubCategorySelected",
            isPathTypeSelected: "paths/isPathTypeSelected",
            zoomToMarker: 'markers/ZoomToMarker',
            zoomToPath: 'paths/ZoomToPath',
            zoomToPathStart: 'paths/ZoomToPathStart',
            zoomToPathEnd: 'paths/ZoomToPathEnd',
            unfocusMarker: 'markers/UnfocusMarker',
            focusMarker: 'markers/FocusMarker',
            focusPath: 'paths/FocusPath',
            unfocusPath: 'paths/UnfocusPath'
        })
        // selectedSubCategories: store.getters['categories/SelectedSubCategories'],
    },
    setup(props, context) {
        const routeer = router;
        let GoogleMapIsFullyLoaded = false;
        const rout = useRoute();
        let poiId = (rout.params.poiId) ? rout.params.poiId : 0;
        let initializedSubCategories = [];
        const initializedPaths = [];
        const landmarks = [];
        let latestZoomPlacement = 'below';
        const defaultIcon = process.env.VUE_APP_STORAGE_URL + process.env.VUE_APP_DEFAULT_ICON;
        const defaultClickedIcon = process.env.VUE_APP_STORAGE_URL + process.env.VUE_APP_DEFAULT_CLICKED_ICON;

        // const visibleSelectedMarkers = ref([]);
        // the google map object
        const map = ref(null);
        // const categories = ref(null);
        // The map element in the template
        const mapDivRef = ref(null);

        const goTo = (route, toSize = 'small') => {
            context.emit('openMenu', toSize);
            routeer.push(route);
        }


        const resetSelected = () => {
            const categories = store.getters['categories/Categories'];
            categories.forEach((category) => {
                category.sub_categories.forEach((subcategory) => {
                    initializedSubCategories[subcategory.guide_danmark_id].forEach((marker) => {
                        marker.setVisible(false);
                    });
                });
            });
            // babyMarkers.forEach((babyMarker) => {
            //     babyMarker.setVisible(false);
            //     babyMarker.setMap(null);
            // });
            // babyMarkers = [];
            store.dispatch('categories/reset');
            store.dispatch('markers/reset');
            store.dispatch('paths/reset');

        };
        const ToggleCategory = (Category) => {
            store.dispatch('categories/toggleCategory', Category.guide_danmark_id);
        };
        const ToggleMarker = (marker) => {
            // const routeer = useRoute();
            // console.log('marker', marker);
            store.dispatch('markers/toggleMarker', {
                subCategoryGuideDanmarkId: marker.subcategoryGuideDanmarkId,
                guideDanmarkId: marker.guideDanmarkId
            });
            // const mark = store.getters['markers/Marker'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId);
            // const subcategory = store.getters['categories/SubCategory'](marker.subcategoryGuideDanmarkId);
            // goTo('/category/' + subcategory.parent_id + '/subcategory/' + marker.subcategoryGuideDanmarkId + '/poi/' + marker.guideDanmarkId)

        };
        const initializeLandmarks = async (id) => {
            const polyline = initializedPaths[id];
            const path = store.getters['paths/Path'](id);
            if (path) {
                if (!landmarks[path.id]) {
                    landmarks[path.id] = [];
                    const polyCalc = (polyline.Distance() / 1000).toFixed(2)
                    for (let i = 0; i < polyline.Distance(); i += 1000) {
                        const kmPoint = polyline.GetPointAtDistance(i);
                        if (kmPoint) {
                            const iCalc = i / 1000

                            const infoWindowContent = " " + iCalc + " km. ud af " + polyCalc + " km.";
                            const landmark = new window.google.maps.Marker({
                                position: kmPoint,
                                // icon: 'https://ds.umvuni.dk/storage/circle-8.png',
                                icon: {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 2,
                                    strokeColor: 'white',
                                    fillColor: 'white',
                                    fillOpacity: 1,
                                },
                                map: map.value,
                                title: infoWindowContent,
                                // label: label,
                                zIndex: Math.round(kmPoint.lat() * -100000) << 5,
                                visible: false
                            });
                            landmarks[path.id].push(landmark);
                        }
                    }
                    const start = new window.google.maps.Marker({
                        position: polyline.getPath().getAt(0),
                        map: map.value,
                        visible: false,
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 4,
                            strokeColor: path.color,
                            strokeOpacity: .5,
                        },
                    });
                    landmarks[path.id].push(start);

                    const end = new window.google.maps.Marker({
                        position: polyline.getPath().getAt(polyline.getPath().getLength() - 1),
                        map: map.value,
                        visible: false,
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 4,
                            strokeColor: path.color,
                            strokeOpacity: .5,
                        },
                    })
                    landmarks[path.id].push(end);
                }
            }
            return true;
        }

        const kmOn = async (id) => {

            const polyline = initializedPaths[id];
            polyline.setOptions({
                strokeWeight: 7,
            });

            await initializeLandmarks(id).then(() => {
                if (landmarks[id] && landmarks[id].length && (landmarks[id].length > 0)) {
                    for (let i = 0; i < landmarks[id].length; i++) landmarks[id][i].setVisible(true);
                }
                const kmImg = document.getElementById('km-img');
                kmImg.style.display = 'block';
            });
        };


        const kmOff = async (id) => {

            const polyline = initializedPaths[id];
            polyline.setOptions({
                strokeWeight: 5,
            });

            if (landmarks[id]) {
                for (let i = 0; i < landmarks[id].length; i++) {
                    landmarks[id][i].setVisible(false);
                }
                const kmImg = document.getElementById('km-img');
                kmImg.style.display = 'none';
            }

        };

        const labelOn = async (id) => {
            const polyline = initializedPaths[id]

            polyline.infowindow.open(
                {
                    map: map.value,
                    shouldFocus: false,
                }
            );


        };

        const labelOff = async (id) => {

            const polyline = initializedPaths[id]

            polyline.infowindow.close();

        };


        const ZoomToMarker = (marker) => {
            // console.log(marker);
            map.value.setZoom(12);
            // if (!store.getters['markers/isMarkerSelected'](marker.category_id, marker.guide_danmark_id)) {
            //     store.dispatch('markers/toggleMarker', {
            //         subCategoryGuideDanmarkId: marker.category_id,
            //         guideDanmarkId: marker.guide_danmark_id
            //     });
            // }
            map.value.panTo({lat: marker.address.GeoCoordinate.Latitude, lng: marker.address.GeoCoordinate.Longitude});
        }
        const ZoomToPathStart = (path) => {
            const polyline = initializedPaths[path.id];
            if (map.value.getZoom() < 12) {
                map.value.setZoom(12);
            }
            map.value.panTo(polyline.getPath().getAt(0));
        }
        const ZoomToPathEnd = (path) => {
            const polyline = initializedPaths[path.id];
            if (map.value.getZoom() < 12) {
                map.value.setZoom(12);
            }
            map.value.panTo(polyline.getPath().getAt(polyline.getPath().getLength() - 1));
        }
        const ZoomToPath = (path) => {
            // console.log(marker);
            const bounds = new window.google.maps.LatLngBounds();
            const polyline = initializedPaths[path.id];
            polyline.getPath().forEach(function (e) {//can't do polyline.getPath()[i] because it's a MVCArray
                bounds.extend(e);
            })
            // map.value.panToBounds(bounds);
            map.value.fitBounds(bounds);
            // if (map.value.getZoom() >= 12) {
            //     kmOn(path.id);
            //     console.log('check check')
            // }
        }

        const unfocusssPath = (id) => {
            const poly = initializedPaths[id];
            // console.log('unfocus poly',[poly]);
            if (poly) {
                const labels = document.getElementsByClassName('polyline-label-focused');
                if (labels && labels.length > 0) {
                    labels.forEach(function (item) {
                        item.closest('div.gm-style-iw-t').id = '';
                    });
                }
                poly.setOptions({
                    strokeOpacity: .5,
                });
                poly.infowindow.setOptions({
                    content: poly.defaultlabel
                });
                kmOff(poly.pathId)
            }
            // map.value.panTo(..env.center);
            // map.value.setZoom(..env.zoom);
        }
        const unfocusAllPaths = () => {
            const labels = document.getElementsByClassName('polyline-label-focused');
            if (labels && labels.length > 0) {
                labels.forEach(function (item) {
                    item.closest('div.gm-style-iw-t').id = '';
                });
            }
            initializedPaths.forEach((poly) => {
                poly.setOptions({
                    strokeOpacity: .5,
                });
                poly.infowindow.setOptions({
                    content: poly.defaultlabel
                });
                kmOff(poly.pathId)
            })
        }
        const unfocusssMarker = (m) => {
            if (m) {
                let marker;
                if (initializedSubCategories.length != 0) {
                    if (m.subcategoryGuideDanmarkId) {
                        marker = initializedSubCategories[m.subcategoryGuideDanmarkId].find((mark) => mark.guideDanmarkId == m.guideDanmarkId);
                    } else if (m.category_id) {
                        marker = initializedSubCategories[m.category_id].find((mark) => mark.guideDanmarkId == m.guide_danmark_id);
                    }
                    if (marker) {

                        if (store.getters['categories/isSubCategorySelected'](marker.subcategoryGuideDanmarkId) && store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
                            const icon = marker.icon;
                            icon.url = marker.clickedicon.url;
                            icon.size = new window.google.maps.Size(32, 32);
                            icon.scaledSize = new window.google.maps.Size(32, 32);
                            marker.setOptions({
                                icon: icon
                            });
                            console.log('icon', [icon]);
                            console.log('marker.clickedIcon', [marker.clickedicon]);
                            console.log('marker.icon', [marker.icon]);
                        } else {
                            marker.setOptions({
                                icon: marker.defaulticon
                            });
                        }
                        if (!store.getters['categories/isSubCategorySelected'](marker.subcategoryGuideDanmarkId) && !store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
                            marker.setVisible(false);
                        }
                    }
                }
            }
        };
        const unfocusAllMarkers = () => {
            const SMarkers = store.getters['markers/SelectedMarkers'];
            if (SMarkers.length > 0) {
                SMarkers.forEach((subCat, index) => {
                    if (subCat != null) {
                        // if(store.getters['categories/isSubCategorySelected'](index)){
                        subCat.forEach((m) => {
                            if (m) {
                                const mark = initializedSubCategories[index].find((mark) => mark.guideDanmarkId === m);
                                if (mark && mark.guideDanmarkId != rout.params.poiId) {
                                    if (store.getters['categories/isSubCategorySelected'](mark.subcategoryGuideDanmarkId) && store.getters['markers/isMarkerSelected'](mark.subcategoryGuideDanmarkId, mark.guideDanmarkId)) {
                                        const icon = mark.icon;
                                        icon.url = mark.clickedicon.url;
                                        icon.size = new window.google.maps.Size(32, 32);
                                        icon.scaledSize = new window.google.maps.Size(32, 32);
                                        mark.setOptions({
                                            icon: icon
                                        });
                                    } else {
                                        mark.setOptions({
                                            icon: mark.defaulticon
                                        });
                                    }
                                    mark.setVisible(true);

                                }
                            }
                        });
                        // }
                        // else{
                        //     subCat.forEach((m) => {
                        //         if (m) {
                        //             if(m != poiId) {
                        //                 store.dispatch('markers/removeFromSelectedMarkers', {
                        //                     subCategoryGuideDanmarkId: index,
                        //                     guideDanmarkId: m
                        //                 })
                        //             }
                        //         }
                        //     });
                        // }
                    }
                });
            }
        };
        const focusssPath = (id, clicked = false) => {
            const fragment = window.location.href
            const arrayFragment = fragment.split('/');
            let pathId = 0;
            unfocusAllMarkers();
            unfocusAllPaths();
            if (arrayFragment[arrayFragment.length - 2] == 'path') {
                pathId = parseInt(arrayFragment[arrayFragment.length - 1]);
            }
            if (pathId == id || clicked) {
                const polyline = initializedPaths[id];
                if (polyline) {
                    if (map.value.getZoom() >= 9) {
                        kmOn(id);
                    }
                    polyline.setMap(map.value);
                    polyline.setVisible(true);
                    polyline.setOptions({
                        strokeOpacity: 1,
                    });
                    polyline.infowindow.setOptions({
                        content: polyline.focusedlabel,
                    });
                    initializedPaths[id].infowindow.open({
                        map: map.value,
                        shouldFocus: false,
                    });
                    const labels = document.getElementsByClassName('polyline-label-focused');
                    if (labels && labels.length > 0) {

                        labels.forEach(function (item) {
                            item.closest('div.gm-style-iw-t').id = 'focused-dialog';
                        });
                    }
                }
            }
        };
        const focusssMarker = (m, initialized = false) => {
            if (m && map.value) {
                const fragment = window.location.href;
                let marker;
                const arrayFragment = fragment.split('/');
                if (arrayFragment[arrayFragment.length - 2] == 'poi') {
                    poiId = parseInt(arrayFragment[arrayFragment.length - 1]);
                }
                // console.log('focusssMarker',[marker]);
                if (!initialized && initializedSubCategories.length != 0) {
                    if (m.subcategoryGuideDanmarkId) {
                        marker = initializedSubCategories[m.subcategoryGuideDanmarkId].find((mark) => mark.guideDanmarkId == m.guideDanmarkId);
                    } else if (m.category_id) {
                        marker = initializedSubCategories[m.category_id].find((mark) => mark.guideDanmarkId == m.guide_danmark_id);
                    }
                }
                // const subcategory = store.getters['categories/SubCategory'](marker.subcategoryGuideDanmarkId);
                if (marker && marker.guideDanmarkId) {
                    marker.setMap(map.value);
                    unfocusAllMarkers();
                    unfocusAllPaths();
                    marker.setOptions({
                        icon: marker.clickedicon
                    });
                    marker.setVisible(true);

                    const subcategory = store.getters['categories/SubCategory'](marker.subcategoryGuideDanmarkId);
                    if (subcategory) {
                        // console.log('marker',[marker.guideDanmarkId]);
                        poiId = marker.guideDanmarkId;
                        // console.log('poiId',[poiId]);'
                        if (store.getters["drawer/isDrawerFull"]) {
                            goTo('/category/' + subcategory.parent_id + '/subcategory/' + marker.subcategoryGuideDanmarkId + '/poi/' + marker.guideDanmarkId, 'full')
                        } else {
                            goTo('/category/' + subcategory.parent_id + '/subcategory/' + marker.subcategoryGuideDanmarkId + '/poi/' + marker.guideDanmarkId, 'half')
                        }
                    }
                }
            }
        };
        const TogglePathType = (pathtype) => {
            // console.log('pathtype', pathtype);
            store.dispatch('paths/togglePathType', pathtype.id);
        };
        const ShowMarker = (marker, initialized = false) => {
            if (map.value) {
                // console.log('ShowMarker',[marker]);
                if (!initialized && initializedSubCategories.length != 0) {
                    if (marker.subcategoryGuideDanmarkId) {
                        marker = initializedSubCategories[marker.subcategoryGuideDanmarkId].find((mark) => mark.guideDanmarkId == marker.guideDanmarkId);
                    } else if (marker.category_id) {
                        marker = initializedSubCategories[marker.category_id].find((mark) => mark.guideDanmarkId == marker.guide_danmark_id);
                    }
                }
                marker.setOptions({
                    icon: marker.defaulticon
                })
                marker.setVisible(true);

            }
        };

        // const ShowSubCategory = (guideDanmarkId) => {
        //     initializedSubCategories[guideDanmarkId].forEach((marker) => {
        //         if (!store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
        //             let poiId = 0;
        //             if(context.params && context.params.poiId) {
        //                 poiId = context.params.poiId
        //             }
        //             if(marker.guideDanmarkId != poiId) {
        //                 store.dispatch('markers/toggleMarker', {
        //                     subCategoryGuideDanmarkId: marker.subcategoryGuideDanmarkId,
        //                     guideDanmarkId: marker.guideDanmarkId
        //                 });
        //                 marker.setMap(map.value);
        //                 marker.setVisible(true);
        //             }
        //         }
        //     });
        // };
        // const HideSubCategory = (guideDanmarkId) => {
        //     const fragment = window.location.href
        //     const arrayFragment = fragment.split('/');
        //     if(arrayFragment[arrayFragment.length - 2] == 'poi') {
        //         poiId = parseInt(arrayFragment[arrayFragment.length - 1]);
        //     }
        //     initializedSubCategories[guideDanmarkId].forEach((marker) => {
        //         initializedSubCategories[guideDanmarkId].forEach((marker) => {
        //             if(poiId != marker.guideDanmarkId){
        //             if (store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
        //
        //                     store.dispatch('markers/toggleMarker', {
        //                         subCategoryGuideDanmarkId: marker.subcategoryGuideDanmarkId,
        //                         guideDanmarkId: marker.guideDanmarkId
        //                     });
        //                     marker.setMap(map.value);
        //                     marker.setVisible(false);
        //                     marker.setOptions({
        //                         icon: marker.defaulticon
        //                     });
        //             }
        //             }
        //         });                // if (store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
        //         //     store.dispatch('markers/toggleMarker', {
        //         //         subCategoryGuideDanmarkId: marker.subcategoryGuideDanmarkId,
        //         //         guideDanmarkId: marker.guideDanmarkId
        //         //     });
        //         // }
        //             // marker.setVisible(false);
        //     });
        // };
        const ShowSubCategory = (guideDanmarkId) => {
            initializedSubCategories[guideDanmarkId].forEach((marker) => {
                marker.setMap(map.value);
                if (store.getters['categories/isSubCategorySelected'](marker.subcategoryGuideDanmarkId) && store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
                    const icon = marker.icon;
                    icon.url = marker.clickedicon.url;
                    icon.size = new window.google.maps.Size(32, 32);
                    icon.scaledSize = new window.google.maps.Size(32, 32);
                    marker.setOptions({
                        icon: icon
                    });
                } else {
                    marker.setOptions({
                        icon: marker.defaulticon
                    });
                }
                marker.setVisible(true);


            });
        };
        const HideSubCategory = (guideDanmarkId) => {
            const fragment = window.location.href
            const arrayFragment = fragment.split('/');
            if (arrayFragment[arrayFragment.length - 2] == 'poi') {
                poiId = parseInt(arrayFragment[arrayFragment.length - 1]);
            }
            initializedSubCategories[guideDanmarkId].forEach((marker) => {
                marker.setMap(map.value);
                if (!store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
                    marker.setVisible(false);
                }
                marker.setOptions({
                    icon: marker.defaulticon
                });

            });                // if (store.getters['markers/isMarkerSelected'](marker.subcategoryGuideDanmarkId, marker.guideDanmarkId)) {
            //     store.dispatch('markers/toggleMarker', {
            //         subCategoryGuideDanmarkId: marker.subcategoryGuideDanmarkId,
            //         guideDanmarkId: marker.guideDanmarkId
            //     });
            // }
            // marker.setVisible(false);
        };
        const ShowPath = (id) => {
            // let defaultInfoWindows = document.getElementsByClassName("polyline-label-default");
            //     if(defaultInfoWindows.length > 0) {
            //         defaultInfoWindows.removeEventListener('click', polylinefocused);
            //     }
            if (map.value && initializedPaths[id]) {
                initializedPaths[id].setMap(map.value);
                initializedPaths[id].setVisible(true);
                // console.log('showPath',[initializedPaths[id]]);
                initializedPaths[id].setOptions({
                    strokeOpacity: .5,
                });
                // console.log('text',[initializedPaths[id].text]);
                initializedPaths[id].infowindow.open({
                    map: map.value,
                    shouldFocus: false,
                });

                focusssPath(id);
            }
            initializeLandmarks(id);

        };
        const HidePath = (id) => {
            if (initializedPaths[id]) {
                initializedPaths[id].setMap(null);
                initializedPaths[id].setVisible(false);
                initializedPaths[id].setOptions({
                    strokeOpacity: 0.5,
                });
                initializedPaths[id].infowindow.close();
                kmOff(id);
            }
            map.value.panTo(env.center);
            map.value.setZoom(env.zoom);
        };
        const imagePath = (image) => {
            if (image) {
                return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
            } else {
                return process.env.VUE_APP_STORAGE_URL + 'Questionmark_1.svg';
            }
        };
        const loadCategories = (categories) => {
            if (categories.length && GoogleMapIsFullyLoaded) {
                if (initializedSubCategories.length !== 0) {
                    initializedSubCategories.forEach((initedCategory) => {
                        if (initedCategory.length !== 0) {
                            initedCategory.forEach((initedMarker) => {
                                // if (initedMarker.getMap() != null) {
                                initedMarker.setVisible(false);
                                initedMarker.setMap(null);
                                // }
                            });
                        }
                    });
                    initializedSubCategories = [];
                }
                // if (babyMarkers && babyMarkers.length > 0) {
                //     babyMarkers.forEach((babyMarker) => {
                //         babyMarker.setVisible(false);
                //         babyMarker.setMap(null);
                //     });
                //     babyMarkers = [];
                // }
                categories.forEach((category) => {
                    category.sub_categories.forEach((subcategory) => {
                        const defaulticon = {
                            url: defaultIcon,
                            size: new window.google.maps.Size(32, 32),
                            scaledSize: new window.google.maps.Size(32, 32)
                        };
                        if (subcategory.icon != null) {
                            if (subcategory.icon.includes('//')) {
                                defaulticon.url = subcategory.icon;
                            } else {
                                defaulticon.url = process.env.VUE_APP_STORAGE_URL + subcategory.icon;
                            }
                        } else if (category.icon != null) {
                            if (category.icon.includes('//')) {
                                defaulticon.url = category.icon;
                            } else {
                                defaulticon.url = process.env.VUE_APP_STORAGE_URL + category.icon;
                            }
                        }
                        const clickedicon = {
                            url: defaultClickedIcon,
                            size: new window.google.maps.Size(44, 44),
                            scaledSize: new window.google.maps.Size(44, 44)
                        };
                        if (subcategory.clicked_icon != null && subcategory.clicked_icon !== 'undefined') {
                            if (subcategory.clicked_icon.includes('//')) {
                                clickedicon.url = subcategory.clicked_icon;
                            } else {
                                clickedicon.url = process.env.VUE_APP_STORAGE_URL + subcategory.clicked_icon;
                            }
                        } else if (category.clicked_icon != null && category.clicked_icon !== 'undefined') {
                            if (category.clicked_icon.includes('//')) {
                                clickedicon.url = category.clicked_icon;
                            } else {
                                clickedicon.url = process.env.VUE_APP_STORAGE_URL + category.clicked_icon;
                            }
                        }
                        if (!initializedSubCategories[subcategory.guide_danmark_id]) {
                            initializedSubCategories[subcategory.guide_danmark_id] = [];
                        }
                        subcategory.markers.forEach((marker) => {
                            const newMarker = new window.google.maps.Marker({
                                position: new window.google.maps.LatLng(marker.address.GeoCoordinate.Latitude, marker.address.GeoCoordinate.Longitude),
                                icon: defaulticon,
                                map: map.value,
                                visible: false,
                                labelContent: marker.name,
                                labelClass: "labels",
                                optimize: false
                            });
                            newMarker.subcategoryGuideDanmarkId = subcategory.guide_danmark_id;
                            newMarker.guideDanmarkId = marker.guide_danmark_id;
                            newMarker.defaulticon = defaulticon;
                            newMarker.clickedicon = clickedicon;

                            // Add a click listener for each marker, and set up the info window.
                            newMarker.addListener("click", () => {
                                focusssMarker(newMarker);
                            });
                            newMarker.addListener('mouseover', () => {
                                if (newMarker.icon.size.width !== 44) {
                                    // console.log('mouseoer',[this]);
                                    const icon = newMarker.icon;
                                    icon.size = new window.google.maps.Size(38, 38),
                                        icon.scaledSize = new window.google.maps.Size(38, 38),
                                        newMarker.setIcon(icon)
                                }
                            });

                            // });
//
// // assuming you also want to hide the infowindow when user mouses-out
                            newMarker.addListener('mouseout', () => {
                                if (newMarker.icon.size.width !== 44) {
                                    const icon = newMarker.icon;
                                    icon.size = new window.google.maps.Size(32, 32),
                                        icon.scaledSize = new window.google.maps.Size(32, 32),
                                        // console.log('icon scale',[icon]);
                                        // console.log('icon scale',[newMarker]);
                                        newMarker.setIcon(icon);
                                }
                                // console.log('icon scale',[newMarker]);
                            });
                            initializedSubCategories[subcategory.guide_danmark_id].push(newMarker);
                        });
                    });
                });
                categories.forEach((category) => {
                    if (store.getters['categories/isCategorySelected'](category.guide_danmark_id)) {
                        category.sub_categories.forEach((subcategory) => {
                            store.dispatch('categories/addToSelectedSubCategories', subcategory.guide_danmark_id);
                        });
                    } else {
                        category.sub_categories.forEach((subcategory) => {
                            if (store.getters['categories/isSubCategorySelected'](subcategory.guide_danmark_id)) {
                                ShowSubCategory(subcategory.guide_danmark_id);
                            }
                        });
                    }
                    category.sub_categories.forEach((subcategory) => {
                        // if (this.SelectedSubCategories && this.SelectedSubCategories.includes(subcategory.guideDanmarkId) && (!this.SelectedCategories || !this.SelectedCategories.includes(category.guideDanmarkId))) {
                        //     this.ShowSubCategory(subcategory.guideDanmarkId);
                        // }
                        subcategory.markers.forEach((marker) => {
                            if (store.getters['markers/isMarkerSelected'](subcategory.guide_danmark_id, marker.guide_danmark_id)) {
                                // if (this.SelectedMarkers && this.SelectedMarkers.includes(marker.guide_danmark_id) && (!this.SelectedCategories || !this.SelectedCategories.includes(category.guide_danmark_id) || !this.SelectedSubCategories || !this.SelectedSubCategories.includes(subcategory.guide_danmark_id))) {
                                ToggleMarker(marker);
                            }
                        });
                    });
                });
            }
        };

        const loadPaths = (paths) => {

            if (paths.length > 0 && GoogleMapIsFullyLoaded) {
                paths.forEach((path) => {
                    fetch(path.gpx)
                        .then(response => response.text())
                        .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
                        //.then(data => console.log(data))
                        .then(doc => {
                            const points = [];
                            // var bounds = new window.google.maps.LatLngBounds();

                            const nodes = [...doc.getElementsByTagName('trkpt')];
                            nodes.forEach(node => {
                                const lat = node.getAttribute("lat");
                                const lon = node.getAttribute("lon");
                                //console.log(lat);

                                const p = new window.google.maps.LatLng(lat, lon);
                                points.push(p);
                                // bounds.extend(p);
                            })
                            const pathType = store.getters['paths/PathType'](path.path_type_id);
                            let label = '<div class="polyline-label-default"><p>' + path.name + '</p></div>';
                            let focusedlabel = '<div class="polyline-label-focused" ><p>' + path.name + '</p></div>';
                            if (pathType && pathType.icon) {
                                label = '<div class="polyline-label-default"><ion-icon src="' + imagePath(pathType.icon) + '" ></ion-icon><p>' + path.name + '</p></div>';
                                focusedlabel = '<div class="polyline-label-focused" ><ion-icon src="' + imagePath(pathType.icon) + '" ></ion-icon><p>' + path.name + '</p></div>';
                            }
                            const polyline = new window.google.maps.Polyline({
                                path: points,
                                strokeColor: path.color,
                                strokeOpacity: .5,
                                strokeWeight: 5,
                                text: label,
                                visible: false,
                            });
                            polyline.pathTypeId = path.path_type_id;
                            polyline.pathId = path.id;
                            polyline.defaultlabel = label;
                            polyline.focusedlabel = focusedlabel;
                            const middlepoint = Math.round(points.length * .5);
                            // console.log(middlepoint);
                            // console.log(points[middlepoint]);
                            polyline.infowindow = new window.google.maps.InfoWindow({
                                content: polyline.text,
                                position: points[middlepoint],
                                disableAutoPan: true
                            });
                            polyline.addListener("click", () => {
                                const pathType = store.getters['paths/PathType'](polyline.pathTypeId);
                                const path = store.getters['paths/Path'](polyline.pathId);
                                focusssPath(path.id, true);
                                goTo('/pathtype/' + pathType.id + '/path/' + path.id, 'half')
                            });
                            polyline.addListener('mouseover', function () {
                                if (polyline.strokeOpacity != 1) {
                                    polyline.setOptions({
                                        strokeOpacity: .75,
                                    });
                                    polyline.infowindow.setOptions({
                                        content: polyline.focusedlabel,
                                    })
                                }
                                // polyline.setOptions({
                                //     strokeOpacity: .75,
                                // });
                            });

// assuming you also want to hide the infowindow when user mouses-out
                            polyline.addListener('mouseout', function () {
                                if (polyline.strokeOpacity != 1) {
                                    polyline.setOptions({
                                        strokeOpacity: .5,
                                    });
                                    polyline.infowindow.setOptions({
                                        content: polyline.defaultlabel,
                                    })
                                }
                            });

                            initializedPaths[path.id] = polyline;
                            if (path.length == null && typeof polyline.Distance === "function") {
                                path.length = Math.round((polyline.Distance() / 1000) * 10) / 10;
                            }
                            // if (!landmarks[path.id]) {
                            //     landmarks[path.id] = [];
                            // }
                            // for (let i = 0; i < polyline.Distance(); i += 1000) {
                            //     const kmPoint = polyline.GetPointAtDistance(i);
                            //     if (kmPoint) {
                            //         const infoWindowContent = "" + i / 1000 + "kilometer ud af " + (polyline.Distance() / 1000).toFixed(2);
                            //         const landmark = new window.google.maps.Marker({
                            //             position: kmPoint,
                            //             // icon: 'https://ds.umvuni.dk/storage/circle-8.png',
                            //             icon: {
                            //                 path: window.google.maps.SymbolPath.CIRCLE,
                            //                 scale: 2,
                            //                 strokeColor: 'white',
                            //                 fillColor: 'white',
                            //                 fillOpacity: 1,
                            //             },
                            //             map: map.value,
                            //             title: infoWindowContent,
                            //             // label: label,
                            //             zIndex: Math.round(kmPoint.lat() * -100000) << 5,
                            //             visible: false
                            //         });
                            //         landmarks[path.id].push(landmark);
                            //     }
                            // }
                            // const start = new window.google.maps.Marker({
                            //     position:polyline.getPath().getAt(0),
                            //     map:map.value,
                            //             visible: false,
                            //         icon: {
                            //             path: window.google.maps.SymbolPath.CIRCLE,
                            //             scale: 4,
                            //             strokeColor: path.color,
                            //             strokeOpacity: .5,
                            //         },
                            // });
                            // landmarks[path.id].push(start);
                            //
                            // const end = new window.google.maps.Marker({
                            //     position:polyline.getPath().getAt(polyline.getPath().getLength()-1),
                            //     map:map.value,
                            //             visible: false,
                            //         icon: {
                            //             path: window.google.maps.SymbolPath.CIRCLE,
                            //             scale: 4,
                            //             strokeColor: path.color,
                            //             strokeOpacity: .5,
                            //         },
                            // })
                            // landmarks[path.id].push(end);
                        })
                });

            }
        };
        const ZoomControl = (controlDiv, map) => {

            // Creating divs & styles for custom zoom control
            controlDiv.style.padding = '5px';
            controlDiv.style.paddingBottom = '75px'
            controlDiv.id = 'zoomButtonsContainer';

            // Set CSS for the control wrapper
            const controlWrapper = document.createElement('div');
            controlWrapper.id = 'zoomButtons';
            controlDiv.appendChild(controlWrapper);

            // Set CSS for the zoomIn
            const zoomInButton = document.createElement('div');
            zoomInButton.id = 'zoomInButton';
            /* Change this to be the .png image you want to use */
            controlWrapper.appendChild(zoomInButton);

            // Set CSS for the zoomOut
            const zoomOutButton = document.createElement('div');
            zoomOutButton.id = 'zoomOutButton';
            /* Change this to be the .png image you want to use */
            controlWrapper.appendChild(zoomOutButton);

            // Setup the click event listener - zoomIn
            window.google.maps.event.addDomListener(zoomInButton, 'click', function () {
                map.value.setZoom(map.value.getZoom() + 1);
            });

            // Setup the click event listener - zoomOut
            window.google.maps.event.addDomListener(zoomOutButton, 'click', function () {
                map.value.setZoom(map.value.getZoom() - 1);
            });

        };
        onMounted(() => {
            // console.log('mounted',[]);
            const key = env.debug;
            const googleMapScript = document.createElement('SCRIPT');
            googleMapScript.setAttribute(
                'src', 'https://maps.googleapis.com/maps/api/js?key=' + key + '&callback=initMap');
            googleMapScript.setAttribute('defer', '');
            googleMapScript.setAttribute('async', '');
            document.head.appendChild(googleMapScript);


        });

        //This func ins calle as soon as the map is initialized
        window.initMap = async () => {
            // window.initMap =  () => {
            // console.log('init map');
            map.value = new window.google.maps.Map(mapDivRef.value, {
                mapTypeId: "roadmap",
                zoom: env.zoom,
                disableDefaultUI: true,
                center: env.center,
                styles: [
                    {
                        "featureType": "poi",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }
                ]
            });
            store.dispatch('markers/init');
            store.dispatch('paths/init');
            store.dispatch('categories/init');

            const googleEpolyScript = document.createElement('SCRIPT');
            googleEpolyScript.setAttribute(
                'src', 'assets/js/v3_epoly.js');
            googleEpolyScript.setAttribute('defer', '');
            googleEpolyScript.setAttribute('async', '');
            document.head.appendChild(googleEpolyScript);
            window.google.maps.event.addListener(map.value, 'tilesloaded', function () {
                GoogleMapIsFullyLoaded = true;
                //clear the listener, we only need it once
                loadCategories(store.getters['categories/Categories']);
                const pathTypes = store.getters['paths/PathTypes'];
                pathTypes.forEach((pathType) => {
                    if (pathType.paths.length > 0) {
                        loadPaths(pathType.paths);
                    }
                });
                setTimeout(async function () {
                        store.dispatch('categories/fetchSelected');
                        store.dispatch('paths/fetchSelected');
                        store.dispatch('markers/fetchVisible');
                        const link = store.getters['defaults/sharedLink'];
                        if (link !== '')
                            ApiService.getSharedInfo(link);
                    },
                    500);
                window.google.maps.event.clearListeners(map.value, 'tilesloaded');
            });
            window.google.maps.event.addListener(map.value, 'zoom_changed', function () {
                this.zoom = map.value.getZoom();
                const newZoomState = (this.zoom >= 10) ? 'below' : 'above';
                // if (newZoomState !== latestZoomPlacement) {
                //     latestZoomPlacement = newZoomState;
                //     const SelectedPathTypes = store.getters['paths/SelectedPathTypes'];
                //     if (SelectedPathTypes.length > 0) {
                //         SelectedPathTypes.forEach((value) => {
                //             const PathType = store.getters['paths/PathType'](value);
                //             PathType.paths.forEach((path) => {
                //                 if (this.zoom >= 12) {
                //                     kmOn(path.id);
                //                 } else {
                //                     kmOff(path.id);
                //                 }
                //
                //             });
                //         });
                //     }
                //     const SelectedPaths = store.getters['paths/SelectedPaths'];
                //     if (SelectedPaths.length > 0) {
                //         SelectedPaths.forEach((index, value) => {
                //             if (this.zoom >= 12) {
                //                 kmOn(index)
                //             } else {
                //                 kmOff(index)
                //             }
                //         });
                //     }
                // }
                if (newZoomState !== latestZoomPlacement) {
                    latestZoomPlacement = newZoomState;
                    const SelectedPaths = store.getters['paths/SelectedPaths'];
                    if (SelectedPaths.length > 0) {
                        SelectedPaths.forEach((index) => {
                            if (this.zoom >= 10) {
                                labelOn(index)
                            } else {
                                labelOff(index)

                            }
                        });
                    }
                }
            });
            const zoomControlDiv = document.createElement('div');
            new ZoomControl(zoomControlDiv, map);

            zoomControlDiv.index = 1;
            map.value.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(zoomControlDiv);

        };
        return {
            latestZoomPlacement,
            mapDivRef,
            map,
            initializedSubCategories,
            loadCategories,
            loadPaths,
            ToggleCategory,
            ToggleMarker,
            TogglePathType,
            ShowPath,
            HidePath,
            ShowMarker,
            resetSelected,
            ShowSubCategory,
            HideSubCategory,
            kmOn,
            kmOff,
            imagePath,
            defaultIcon,
            defaultClickedIcon,
            ZoomToMarker,
            ZoomToPath,
            ZoomToPathStart,
            ZoomToPathEnd,
            unfocusssMarker,
            unfocusssPath,
            unfocusAllPaths,
            unfocusAllMarkers,
            focusssMarker,
            focusssPath,
            rout,
            poiId
        };
    },
    methods: {
        ...mapActions({
            addToSelectedSubCategories: 'categories/addToSelectedSubCategories',
            removeFromSelectedSubCategories: 'categories/removeFromSelectedSubCategories',
            fetchCategories: 'categories/fetchCategories'

        }),
        toggleCategory(guideDanmarkId) {
            this.ToggleCategory(guideDanmarkId);
        },
        // togglePathType(Id) {
        //     // this.TogglePath(Id);
        // },
        resetMarkers() {
            this.ResetMarkers();
        },
        visibleSelectedSubcategoriesOnThisCategory(Cat) {
            let count = 0;
            // console.log('count',count);
            if (Cat) {
                Cat.sub_categories.forEach((SubCat) => {
                    if (store.getters['categories/isSubCategorySelected'](SubCat.guide_danmark_id) > 0) {
                        count++;
                    }
                });
            }
            // console.log('count',count);
            return count;
        },
        visibleSelectedMarkersOnThisCategory(Cat) {
            let count = 0;
            // console.log('count',count);
            if (Cat) {
                Cat.sub_categories.forEach((SubCat) => {
                    count += store.getters['markers/SubCategoryCount'](SubCat.guide_danmark_id);
                });
            }
            // console.log('count',count);
            return count;
        },
        visibleSelectedPathsOnThisPathType(PathType) {
            let count = 0;
            if (PathType) {
                PathType.paths.forEach((path) => {
                    count += store.getters['paths/isPathSelected'](path.id);
                });
            }
            // console.log('count',count);
            return count;
        },
        visibleSelectedMarkersOnThisSubCategory(CategoryId) {
            if (CategoryId) {
                return store.getters['markers/SubCategoryCount'](CategoryId);
            } else {
                return 0;
            }
        },
        selectedCategory(CategoryId) {
            return this.visibleSelectedCategories.includes(CategoryId)
        },
        // loadPathType(pathType) {
        //         this.$emit("unloadPath"),
        //     this.$emit('loadPathType', pathType);
        // },
        // loadCategory(Category) {
        //     this.$emit('unloadMarker');
        //     this.$emit('unloadSubCategory')
        //     this.$emit('loadCategory', Category);
        // },
        // loadPath(path) {
        //     this.$emit('loadPath', path);
        // },
        // loadMarker(Marker) {
        //     this.$emit('loadMarker', Marker);
        // },
        openMenu(toSize = 'small') {
            this.$emit('openMenu', toSize);
        },
        polylinefocused(pathTypeId, pathId) {
            const pathType = store.getters['paths/PathType'](pathTypeId);
            const path = store.getters['paths/Path'](pathId);
            this.focusssPath(path.id, true);
            this.goTo('/pathtype/' + pathType.id + '/path/' + path.id, 'half')
        }
    },
    watch: {
        zoomToMarker(marker) {
            if (marker && marker.id && marker.id != 0) {
                this.ZoomToMarker(marker);
            }
        },
        zoomToPath(path) {
            if (path && path.id && path.id != 0) {
                this.ZoomToPath(path);
            }
        },
        zoomToPathStart(path) {
            this.ZoomToPathStart(path);
        },
        zoomToPathEnd(path) {
            this.ZoomToPathEnd(path);
        },
        unfocusMarker: {
            deep: true,
            handler: function (marker) {
                // console.log('marker',[marker]);
                this.unfocusssMarker(marker);
            }
        },
        focusMarker: {
            deep: true,
            handler: function (marker) {
                // console.log('marker',[marker]);
                this.focusssMarker(marker);
            }
        },
        focusPath: {
            deep: true,
            handler: function (path) {
                // console.log('path',[path]);
                if (path && path.id && path.id != 0)
                    this.focusssPath(path.id);
            }
        },
        unfocusPath: {
            deep: true,
            handler: function (path) {
                console.log('unfocus',[path], path.id);
                this.unfocusssPath(path.id);

            }
        },
        categories(categories) {
            this.loadCategories(categories);
        },
        pathTypes(pathTypes) {
            if (pathTypes.length > 0) {
                pathTypes.forEach((pathType) => {
                    if (pathType.paths.length > 0) {
                        this.loadPaths(pathType.paths);
                    }
                });
            }
        },

        selectedCategories: {
            deep: true,
            handler: function (selectedCategories) {
                if (this.currentSelectedCategories != selectedCategories) {
                    if (this.currentSelectedCategories && this.currentSelectedCategories.length > 0) {
                        this.currentSelectedCategories.forEach((oldCategoryGuideDanmarkId) => {
                            const Category = store.getters['categories/Category'](oldCategoryGuideDanmarkId);
                            Category.sub_categories.forEach((subCategory) => {
                                this.removeFromSelectedSubCategories(subCategory.guide_danmark_id);
                                // this.HideSubCategory(subCategory.guide_danmark_id);
                            });
                        });
                    }
                    if (selectedCategories.length > 0) {
                        selectedCategories.forEach((newCategoryGuideDanmarkId) => {
                            const Category = store.getters['categories/Category'](newCategoryGuideDanmarkId);
                            Category.sub_categories.forEach((subCategory) => {
                                this.addToSelectedSubCategories(subCategory.guide_danmark_id);
                                // this.ShowSubCategory(subCategory.guide_danmark_id);
                            });
                        });
                    }
                    this.currentSelectedCategories = selectedCategories.slice(0);
                }
            }
        },
        selectedSubCategories: {
            deep: true,
            handler: function (selectedSubCategories) {
                if (this.currentSelectedSubCategories != selectedSubCategories) {
                    if (this.currentSelectedSubCategories && this.currentSelectedSubCategories.length > 0) {
                        this.currentSelectedSubCategories.forEach((oldSubCategory) => {
                            if (!selectedSubCategories.includes(oldSubCategory)) {
                                this.HideSubCategory(oldSubCategory);
                            }
                        });
                        this.currentSelectedSubCategories = [];
                    }
                    if (selectedSubCategories.length > 0) {
                        selectedSubCategories.forEach((newSubCategory) => {
                            if (!this.currentSelectedSubCategories.includes(newSubCategory)) {
                                this.ShowSubCategory(newSubCategory);
                            }
                            if (!this.currentSelectedSubCategories) {
                                this.currentSelectedSubCategories = [];
                            }
                            this.currentSelectedSubCategories.push(newSubCategory);
                        });
                    }
                }
            }
        },
        SelectedMarkers: {
            deep: true,
            handler: function (SelectedMarkers) {
                let focusedMarker = {};
                const fragment = window.location.href
                const arrayFragment = fragment.split('/');
                let poiId = 0;
                if (arrayFragment[arrayFragment.length - 2] == 'poi') {
                    poiId = parseInt(arrayFragment[arrayFragment.length - 1]);
                }


                // console.log('poi',[poiId]);
                if (SelectedMarkers != this.currentSelectedMarkers) {
                    if (this.currentSelectedMarkers && this.currentSelectedMarkers.length > 0) {
                        this.currentSelectedMarkers.forEach((key, value) => {
                            if (this.currentSelectedMarkers[value] && this.currentSelectedMarkers[value].length > 0) {
                                this.currentSelectedMarkers[value].forEach((marker) => {
                                    // console.log('mark', marker);
                                    if (marker != poiId) {
                                        this.unfocusssMarker({subcategoryGuideDanmarkId: value, guideDanmarkId: marker});
                                    }
                                });
                            }
                        });
                        this.currentSelectedMarkers = [];
                    }
                    if (SelectedMarkers.length > 0) {
                        SelectedMarkers.forEach((key, value) => {
                            if (SelectedMarkers[value] && SelectedMarkers[value].length > 0) {
                                SelectedMarkers[value].forEach((marker) => {
                                    if (marker != poiId) {
                                        this.ShowMarker({subcategoryGuideDanmarkId: value, guideDanmarkId: marker});
                                        // this.focusssMarker({subcategoryGuideDanmarkId: value, guideDanmarkId: marker});
                                    }
                                    if (!this.currentSelectedMarkers[value]) {
                                        this.currentSelectedMarkers[value] = [];
                                    }
                                    this.currentSelectedMarkers[value].push(marker);
                                    if (poiId != 0) {
                                        if (marker == poiId) {
                                            focusedMarker = {subcategoryGuideDanmarkId: value, guideDanmarkId: marker}
                                        }
                                    }
                                });
                            }
                        });
                    }
                    if (focusedMarker.guideDanmarkId) {
                        this.focusssMarker(focusedMarker);
                    }
                    // console.log('curr', this.currentSelectedMarkers);
                    // this.currentSelectedMarkers = SelectedMarkers.slice(0);
                }
            }

        },
        selectedPathTypes: {
            deep: true,
            handler: function (selectedPathTypes) {
                if (selectedPathTypes != this.currentSelectedPathTypes) {
                    if (this.currentSelectedPathTypes && this.currentSelectedPathTypes.length > 0) {
                        this.currentSelectedPathTypes.forEach((PathTypeId) => {
                            if (!selectedPathTypes.includes(PathTypeId)) {
                                const pathtype = this.pathTypes.find((pt) => pt.id === PathTypeId);
                                pathtype.paths.forEach((path) => {
                                    if (store.getters['paths/isPathSelected'](path.id)) {
                                        store.dispatch('paths/togglePath', path.id)
                                        // this.HidePath(path.id);
                                    }
                                });
                            }
                        });
                        this.currentSelectedPathTypes = [];
                    }
                    if (selectedPathTypes.length > 0) {
                        selectedPathTypes.forEach((PathTypeId) => {
                            if (!this.currentSelectedPathTypes.includes(PathTypeId)) {
                                const pathtype = this.pathTypes.find((pt) => pt.id === PathTypeId);
                                pathtype.paths.forEach((path) => {
                                    if (!store.getters['paths/isPathSelected'](path.id)) {
                                        store.dispatch('paths/togglePath', path.id)
                                    }
                                    // this.ShowPath(path.id);
                                });
                                this.currentSelectedPathTypes.push(PathTypeId);

                            }
                        });


                    }
                }
                // console.log('curr', this.currentSelectedPathTypes);
                // this.currentselectedPathTypes = selectedPathTypes.slice(0);
            }
            // if (this.currentSelectedPathTypes != selectedPathTypes) {
            //     if (this.currentSelectedPathTypes.length < selectedPathTypes.length) {
            //         selectedPathTypes.forEach((PathTypeId) => {
            //             if (!this.currentSelectedPathTypes.includes(PathTypeId)) {
            //                 const pathtype = this.pathTypes.find((pt) => pt.id === PathTypeId);
            //                 pathtype.paths.forEach((path) => {
            //                     this.ShowPath(path.id);
            //                 });
            //             }
            //         });
            //     } else if (this.currentSelectedPathTypes.length > selectedPathTypes.length) {
            //         this.currentSelectedPathTypes.forEach((PathTypeId) => {
            //             if (!selectedPathTypes.includes(PathTypeId)) {
            //                 const pathtype = this.pathTypes.find((pt) => pt.id === PathTypeId);
            //                 pathtype.paths.forEach((path) => {
            //                     this.HidePath(path.id);
            //                 });
            //             }
            //         });
            //     }
            //     this.currentSelectedPathTypes = selectedPathTypes.slice(0);
            // }
        },
        selectedPaths: {
            deep: true,
            handler: function (selectedPaths) {
                if (this.currentSelectedPaths != selectedPaths) {
                    if (this.currentSelectedPaths && this.currentSelectedPaths.length) {
                        this.currentSelectedPaths.forEach((oldPath) => {
                            if (!selectedPaths.includes(oldPath)) {
                                this.HidePath(oldPath);
                            }
                        });
                        this.currentSelectedPaths = [];
                    }
                    if (selectedPaths.length > 0) {
                        selectedPaths.forEach((newPath) => {
                            if (!this.currentSelectedPaths.includes(newPath)) {
                                this.ShowPath(newPath);
                                this.currentSelectedPaths.push(newPath);
                            }
                        });
                    }
                }
            }
        },
    },
});
</script>

<style lang="css">
.map {
    width: 100%;
    height: 100vh;
    background-color: azure;
}

ion-badge {
    margin-left: 5px;
    border-radius: 50%;
}

div#googlemap-categories-list-parent {
    overflow: hidden;
    height: 43px;
}

div#googlemap-categories-list-parent, #googlemap-pathtype-list {
    position: absolute;
    white-space: nowrap;
    transition: .2s ease-out;
}

#googlemap-pathtype-list {
    width: 96vw;
    margin: 0 2vw;
}

div#googlemap-categories-list-parent.logo-active {
    z-index: 1;

}

div#googlemap-categories-list-parent.logo-not-active {
    z-index: 1000;
}

@media screen and (min-width: 850px) {
    div#googlemap-categories-list-parent {
        top: 18px;
        width: calc(80vw);
        margin: 0 10vw;
    }

    .main-toolbar .sc-ion-buttons-md-h {
        padding-top: 8px;
    }

    .main-toolbar #menuButtons {
        padding-top: 20px;
    }
}

@media screen and (min-width: 1400px) {
    div#googlemap-categories-list-parent {
        top: 30px;
    }

    .main-toolbar .sc-ion-buttons-md-h {
        padding-top: 20px;
    }

    .main-toolbar #menuButtons {
        padding-top: 32px;
        padding-right: 15px;
    }

    .main-toolbar #logoButtons {
        padding-left: 15px;
    }
}

@media screen and (max-width: 850px) {
    div#googlemap-categories-list-parent {
        top: 49px;
        width: calc(96vw);
        margin: 0 2vw;
    }
}

div#googlemap-categories-list-child {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    height: 45px;
    width: 100%;
}

#googlemap-pathtype-list {
    bottom: 4vh;
    height: 65px;
    overflow: hidden;
}

#googlemap-pathtype-list-bike {
    bottom: 4vh;
    height: 65px;
    overflow: hidden;
}

#googlemap-categories-list-child div, #googlemap-pathtype-list ion-item {
    margin-right: 10px;
    display: inline-block;
    position: relative;
}

#googlemap-categories-list-child ion-button:hover {
    background-color: var(--hover-background);
}

#googlemap-categories-list-child div:first-child {
    margin-left: 10px;
}

.toolbar-background {
    background: transparent;
}

#googlemap-categories-list-child ion-button:hover, #googlemap-pathtype-list thumbnail:hover {
    cursor: pointer;
}

#googlemap-categories-list-child ion-button, #googlemap-pathtype-list ion-button {
    --border-radius: 5px;
    border-radius: 5px;
    box-shadow: 3px 3px 6px #888888;
}

#googlemap-categories-list-child ion-badge, #googlemap-pathtype-list ion-badge {
    height: 22px;
    width: 22px;
    text-align: center;
    font-size: 13px;
    padding-top: 4px;
    position: absolute;
}

#googlemap-categories-list-child ion-badge {
    position: absolute;
    top: 21px;
    background-color: var(--red);
    margin-left: 5px;
    right: -5px;
}

#googlemap-pathtype-list ion-badge {
    top: -10px;
    right: -8px;
    z-index: 99;
}

#googlemap-pathtype-list #badge14 {
    background: var(--red);
    top: -10px;
    right: -8px;
    z-index: 99;
}

#googlemap-pathtype-list ion-thumbnail figure img {
    height: 33px;
    margin: auto;
}

#googlemap-pathtype-list ion-thumbnail figure {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    font-size: 9px;
    padding: 0;
}

#googlemap-pathtype-list ion-thumbnail {
    display: inline-block;
    margin: unset;
    background-color: white;
    padding: 4px;
    box-shadow: 3px 3px 6px #888888;
    position: relative;
    cursor: pointer;
}

#googlemap-pathtype-list ion-thumbnail ion-icon {
    border-radius: 5px ;
    width: 48px;
    height: 30px;
}

#googlemap-pathtype-list ion-thumbnail, #googlemap-pathtype-list ion-thumbnail ion-icon {
width: 50px;
}

#googlemap-pathtype-list ion-thumbnail:hover, #googlemap-pathtype-list ion-thumbnail:hover ion-icon {
    background-color: var(--red);
    color: var(--white);
    fill: var(--white);
}
 #googlemap-pathtype-list  #helloid15:hover, #googlemap-pathtype-list #helloid15:hover ion-icon {
    background-color: #3880ff;
    color: var(--white);
    fill: var(--white);
}



#googlemap-pathtype-list ion-thumbnail, #googlemap-pathtype-list ion-thumbnail ion-img {
    border-radius: 5px;
}

#googlemap-pathtype-list ion-thumbnail:not(:last-child) {
    margin-right: 10px;
}

.polyline-label-default, .polyline-label-focused {
    display: inline-flex;
    padding: 2px;
}

.polyline-label-default, .polyline-label-default ion-icon {
    background-color: var(--white);
    color: var(--black);
    fill: var(--black);
}

.polyline-label-focused, .polyline-label-focused ion-icon {
    background-color: var(--red);
    color: var(--white);
    fill: var(--white);

}

.polyline-label-default ion-icon, .polyline-label-focused ion-icon {
    float: left;
    font-size: 18px;
}

.polyline-label-default p, .polyline-label-focused p {
    font-size: 12px;
    margin: 3px;
    font-weight: 500;
}

div[role="dialog"] button {
    display: none !important;
}

div[role="dialog"] {
    padding: 0 !important;
}

div[role="dialog"] .gm-style-iw-d {
    padding: 0 !important;
    display: inline;
}

@media screen and (max-width: 850px) {
    #zoomButtonsContainer {
        display: none;
    }
}

#zoomButtonsContainer {
    z-index: 10 !important;
    /*left: 5px;*/
    /*bottom: 25px;*/
    left: 10px !important;
    bottom: 28px !important;
}

#zoomButtons {
    background-color: var(--white);
    border-style: solid;
    border-color: var(--white);
    border-width: 0;
    border-radius: 7px;
    cursor: pointer;
    text-align: center;
    width: 32px;
    height: 64px;
    overflow: hidden;
}

#zoomInButton, #zoomOutButton {
    height: 32px;
    width: 32px;
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
}

#zoomInButton {
    background-image: url('https://ds.umvuni.dk/assets/icon/navigate_plus.png');
}

#zoomOutButton {
    background-image: url('https://ds.umvuni.dk/assets/icon/navigate_minus.png');
}

#km-img {
    position: absolute;
    bottom: 35px;
    right: 35px;
    background-color: #ffffffdb;
    border-radius: 4px;
    padding: 5px;
    display: none;
}

#focused-dialog::after {
    background: linear-gradient(
        45deg, #d13339 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
    box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
}
</style>

