
    import {IonItem, IonLabel, IonList, IonImg, IonThumbnail} from '@ionic/vue';
    import {defineComponent} from 'vue';
    import {mapActions, mapGetters} from "vuex";
    import {chevronBackOutline, chevronForwardOutline} from "ionicons/icons";
    import {useRouter} from "vue-router";

    export default defineComponent({
        name: "PathTypeList",
        setup() {
            const router = useRouter();
            return {
                chevronBackOutline,
                chevronForwardOutline,
                router
            }
        },
        computed: {
            ...mapGetters({
                pathTypes: "paths/PathTypes",
                isPathTypeSelected: 'paths/isPathTypeSelected',
                isDrawerSmall: "drawer/isDrawerSmall",
            })
        },
        components: {
            IonList,
            IonItem,
            IonLabel, IonImg, IonThumbnail
        },
        methods: {
            ...mapActions({
                togglePathType: 'paths/togglePathType',
            }),
            imagePath(image: any){
                if(image) {
                    return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
                }else{
// return process..env.VUE_APP_STORAGE_URL+'path-types/June2021/0OmhkTGBqLT7TcLCCcK0.svg';
return 'https://ds.umvuni.dk/storage/Questionmark_1.svg';
                }
            }
        },
    });
