
export const env = {
    release: 'AIzaSyDkgC7fs7a9p-sbm4RBc-WIQRfJ1FHhmoQ',
    debug: 'AIzaSyA79y3HikklAshcGQ851sl6bHqYjVnWH0o',
    center: {lat: 55.024890, lng: 9.532307},
    zoom: 9,
    homeUrl: 'https://www.visitsonderjylland.dk/',
    kontaktUrl: 'https://www.visitsonderjylland.dk/turist/information/kontakt',
    newsUrl: 'https://www.visitsonderjylland.dk/turist/information/nyhedsbrev'
};
