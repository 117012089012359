
    import {
        IonGrid,
        IonRow,
        IonCol,
        IonThumbnail,
        IonIcon
    } from '@ionic/vue';
    import {defineComponent} from 'vue';
    import {env} from "@/services/globals";
    import LanguageMenu from "@/components/LanguageMenu.vue";
    export default defineComponent({
        name: "Footer",
        components: {
            LanguageMenu,
            IonGrid,
            IonRow,
            IonCol,
            IonThumbnail,
            IonIcon
        },
        setup() {
            const Icon = (icon: string) => {
                return process.env.VUE_APP_STORAGE_URL +''+ icon;
            };
            return {
                Icon,
                env
            }
        },
        methods: {},
    });
