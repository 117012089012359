
import {
    IonApp, IonButtons, IonMenu, IonToolbar, IonIcon, IonContent, IonButton, IonImg, IonFabButton, IonSplitPane, IonRouterOutlet
} from '@ionic/vue';
    import {defineComponent, ref} from 'vue';
    import {
        archiveOutline,
        menuOutline,
        archiveSharp,
        bookmarkOutline,
        bookmarkSharp,
        heartOutline,
        heartSharp,
        mailOutline,
        mailSharp,
        paperPlaneOutline,
        paperPlaneSharp,
        trashOutline,
        trashSharp,
        warningOutline,
        warningSharp,
        chevronForwardOutline,
        chevronBackOutline,
        star
    } from 'ionicons/icons';
    import {useI18n} from "vue-i18n";
    import {mapGetters} from "vuex";
    import {env} from "@/services/globals";
    import ContentDrawer from "@/components/ContentDrawer.vue";
    import GoogleMap from "@/components/GoogleMap.vue";
import {store} from "@/store";
import {Network} from "@capacitor/core";

    export default defineComponent({
        name: 'App',
        components: {
            IonApp,
            IonMenu,
            IonToolbar,
            IonButtons,
            IonIcon,
            IonImg,
            IonContent,
            IonButton,
            GoogleMap,
            IonFabButton,
            IonSplitPane,
            IonRouterOutlet,
            ContentDrawer

        },
        data() {
            const isMenuOpen = false;
            const isHomeLogoActive: any = false;
            const appMenu = document.getElementById('appMenu');
            const googlemapCategoriesList = document.getElementById('googlemap-categories-list-parent');
            const googlemapPathtypeList = document.getElementById('googlemap-pathtype-list');
            const openMenuFab = document.getElementById('menu-button');
            return {
                windowWidth: window.innerWidth,
                initialMenuWidth: '0',
                menuWidth: '0',
                isMenuOpen,
                isHomeLogoActive,
                appMenu,
                googlemapCategoriesList,
                googlemapPathtypeList,
                openMenuFab
            };
        },
        mounted() {
            this.appMenu = document.getElementById('appMenu');
            this.googlemapCategoriesList = document.getElementById('googlemap-categories-list-parent');
            this.googlemapPathtypeList = document.getElementById('googlemap-pathtype-list');
            this.openMenuFab = document.getElementById('menu-button');
            store.dispatch('drawer/setIsDrawerActive',(window.innerWidth < 850));
            window.onresize = () => {
                console.log('resize');
                this.windowWidth = window.innerWidth
                if(this.isMenuOpen && this.appMenu != null){
                    this.initialMenuWidth = (parseInt(window.getComputedStyle(this.appMenu).getPropertyValue('--width'))) + 'px';
                    this.menuWidth = this.initialMenuWidth;
                }
                store.dispatch('drawer/setIsDrawerActive',(window.innerWidth < 850));
            }
            if(this.appMenu != null) {
                this.initialMenuWidth = (parseInt(window.getComputedStyle(this.appMenu).getPropertyValue('--width'))) + 'px';
                this.menuWidth = this.initialMenuWidth;
                if(parseInt(this.initialMenuWidth) > 0){
                    this.isMenuOpen = true;
                    if(!this.isDrawerActive){
                    if(this.googlemapCategoriesList != null){
                        this.googlemapCategoriesList.style.width = 'calc(80vw - ' + parseInt(this.menuWidth) +'px)';
                    }
                    if(this.googlemapPathtypeList != null){
                        this.googlemapPathtypeList.style.width = 'calc(96vw - ' + parseInt(this.menuWidth) +'px)';
                    }
                    }
                }
            }
        },
        computed: {
            ...mapGetters({
                categories: "categories/Categories",
                pathTypes: "paths/PathTypes",
                focusedElement: "focusedElement/focusedElement",
                isDrawerSmall: "drawer/isDrawerSmall",
                isDrawerFull: "drawer/isDrawerFull",
            }),
            LogoImg() {
                return process.env.VUE_APP_STORAGE_URL + 'mojn-logo.svg';
            },
            MenuImg() {
                return process.env.VUE_APP_STORAGE_URL + 'Menu.png';
            },
            isDrawerActive() {
                console.log('window.innerWidth < 850', [window.innerWidth < 850]);
                return window.innerWidth < 850;
            }
        },
        setup() {
            // const route = useRoute();
            const Drawer = ref();
            const t = useI18n();
            const loadedPathType = ref();
            const loadedPath = ref();
            const loadedCategory = ref();
            const loadedSubCategory = ref();
            const loadedMarker = ref();
            return {
                archiveOutline,
                archiveSharp,
                bookmarkOutline,
                bookmarkSharp,
                heartOutline,
                heartSharp,
                mailOutline,
                mailSharp,
                paperPlaneOutline,
                paperPlaneSharp,
                chevronForwardOutline,
                chevronBackOutline,
                trashOutline,
                trashSharp,
                warningOutline,
                warningSharp,
                menuOutline,
                star,
                env,
                t,
                loadedPathType,
                loadedPath,
                loadedCategory,
                loadedSubCategory,
                loadedMarker,
                Drawer
                // isSelected: (url: string) => url === route.path ? 'selected' : ''
            }
        },
        watch: {
            categories(categories) {
                if (this.loadedCategory != null) {
                    this.loadedCategory = categories.find((cat: any) => {
                        return cat.guide_danmark_id == this.loadedCategory.guide_danmark_id
                    });
                    // if(!this.loadedCategory){
                    //     this.unloadCategory();
                    // }
                }
                if (this.loadedSubCategory && this.loadedCategory) {
                    this.loadedSubCategory = this.loadedCategory.sub_categories.find((subCat: any) => {
                        return subCat.guide_danmark_id == this.loadedSubCategory.guide_danmark_id
                    });
                    // if(!this.loadedSubCategory){
                    //     this.unloadSubCategory();
                    // }
                }
                if (this.loadedMarker && this.loadedSubCategory) {
                    this.loadedMarker = this.loadedSubCategory.markers.find((mark: any) => {
                        return mark.guide_danmark_id == this.loadedMarker.guide_danmark_id
                    });
                    // if(!this.loadedMarker){
                    //     this.unloadMarker();
                    // }
                }
            },
            menuWidth(newWidth, oldWidth){
                if(parseInt(newWidth) > parseInt(oldWidth) && this.isMenuOpen) {
                    console.log('Initial Width Diagnosis');
                }else{
                if(this.appMenu != null){
                    this.appMenu.style.width = this.menuWidth;
                    this.isMenuOpen = !this.isMenuOpen;
                    if(!this.isDrawerActive){
                    if(this.googlemapCategoriesList != null){
                        this.googlemapCategoriesList.style.width = 'calc(80vw - ' + parseInt(this.menuWidth) +'px)';
                    }
                    if(this.googlemapPathtypeList != null){
                        this.googlemapPathtypeList.style.width = 'calc(96vw - ' + parseInt(this.menuWidth) +'px)';
                    }
                    }
                }
                }
            },
            pathTypes(pathTypes) {
                if (this.loadedPathType) {
                    this.loadedPathType = pathTypes.find((pathType: any) => {
                        return pathType.id == this.loadedPathType.id
                    });
                    // if(!this.loadedPathType){
                    //     this.unloadPathType();
                    // }
                }
                if (this.loadedPath && this.loadedPathType) {
                    this.loadedPath = this.loadedPathType.paths.find((path: any) => {
                        return path.id == this.loadedPath.id
                    });
                    // if(!this.loadedPath){
                    //     this.unloadPath();
                    // }
                }

            }
        },
        methods: {
            ToggleMenu() {
                // const appMenu = document.getElementById('appMenu');
                if (this.windowWidth < 850) {
                    this.Drawer.toggleDrawer('full');
                } else {
                    if (this.isMenuOpen) {
                        this.menuWidth = '0';
                    } else {
                        this.menuWidth = this.initialMenuWidth;
                    }
                }
            },
            openMenu(toSize: any){
                if (this.windowWidth < 850) {
                    this.Drawer.toggleDrawer(toSize);
                } else {
                    this.menuWidth = this.initialMenuWidth;
                }
            },
        }
    });
