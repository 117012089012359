
    import {
        IonItem,
        //IonButton,
        IonPopover,
        IonContent,
        IonList,
        IonLabel,
    } from "@ionic/vue";
    import {defineComponent, ref} from "vue";
    import Popover from './Popover.vue'
    import {langs} from "@/locales";
    import {store} from "@/store";
    import {mapGetters} from "vuex";
    //import {chevronBackOutline, chevronForwardOutline} from "ionicons/icons";

    export default defineComponent({
        name: "LanguageMenu",
        setup() {
            const isOpenRef = ref(false);
            const setOpen = (state: boolean) => {
                isOpenRef.value = state;
                // console.log(state);
            }
            return {isOpenRef, setOpen}
            
        },
        mounted(){
            if(this.chosenLanguage){
                this.$i18n.locale = this.chosenLanguage.locale;
            }else{
                this.$i18n.locale = 'da';
            }
        },
        data() {
            return {
                langs: langs,
            }
        },
        methods: {
            chooseLanguage(language: any){
                this.$i18n.locale = language.locale;
                store.dispatch('defaults/setLanguage',language)
                this.isOpenRef = false;

            }
        },
        components: {
            IonItem,
            //IonButton,
            IonPopover,
            IonContent,
            IonList,
            Popover,
            IonLabel,
        },
        computed:{
          ...mapGetters({
              chosenLanguage: 'defaults/Language'
          })
        },
        watch:{
chosenLanguage(newLanguage){
    this.$i18n.locale = newLanguage.locale;
}
        }
    });
