
const state = {
    isDrawerActive: (window.innerWidth <= 850),
    isDrawerSmall: false,
    isDrawerFull: false,
    toggle: ''
};

const getters = {
    isDrawerActive: (state: { isDrawerActive: any }) => {
        return state.isDrawerActive;
    },
    isDrawerSmall: (state: any) => {
        if(state.isDrawerActive) {
            return state.isDrawerSmall;
        }
        else{
            return false;
        }
    },
    isDrawerFull: (state: any) => {
        if(state.isDrawerActive) {
            return state.isDrawerFull;
        }
        else{
            return false;
        }
    },
    toggle: (state: {toggle: any})=>{
        return state.toggle;
    }
};

const actions = {
    setIsDrawerActive({commit}: any, status: any){
            commit("updateIsDrawerActive", status);
    },
    setIsDrawerSmall({commit}: any, status: any){
            commit("updateIsDrawerSmall", status);
    },
    setIsDrawerFull({commit}: any, status: any){
            commit("updateIsDrawerFull", status);
    },
    toggle({commit}: any, status: any){
        console.log('toggle', status);
        commit('toggle',status);
    }
};

const mutations = {
    updateIsDrawerActive(state: {
        isDrawerActive: any;
    }, status: any) {
        console.log('isActive',[status]);
        state.isDrawerActive = status;
    },
    updateIsDrawerSmall(state: {
        isDrawerSmall: any;
    },status: any) {
        state.isDrawerSmall = status;
    },
    updateIsDrawerFull(state: {
        isDrawerFull: any;
    }, status: any) {
        state.isDrawerFull = status;
    },
    toggle(state: {toggle: any}, status: any){
        state.toggle = status;
        setTimeout(function() {
            state.toggle = '';
        }, 100);
    }
};
export const drawer = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
