import { StorageService } from "@/services/storage.service";
import {store} from "@/store/index";
import ApiService from "@/services/api.service";

const state = {
    language: {iso2: 'dk', locale: 'da', value: 'Dansk'},
    sharedLink: ''
}
    const getters = {
        Language: (state: { language: any }) => {
            return state.language;
        },
        sharedLink: (state: { sharedLink: any }) => {
            return state.sharedLink;
        }
    }
        const actions = {
            async setLanguage(context: any, language: any) {
                console.log(language);
                if (language) {
                    context.commit("setLanguage", language);
                    ApiService.setLocale(language.locale);
                    store.dispatch('markers/init');
                    store.dispatch('paths/init');
                    store.dispatch('categories/init');
                    store.dispatch('dashboard/getDashboard')
                }
            },
            setSharedLink(context: any, link: any) {
                context.commit('setSharedLink', link);
            }
        }
            const mutations = {
                setLanguage(state: {
                    language: any;
                }, language: any) {
                    state.language = language;
                    StorageService.setObject('selected_language', state.language);
                },
                setSharedLink(state: {
                    sharedLink: any;
                }, link: any) {
                    state.sharedLink = link;
                }
            }



export const defaults = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
