import {createRouter, createWebHistory,} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import Dashboard from "@/components/Dashboard.vue";
import LoadedCategory from "@/components/LoadedCategory.vue";
import LoadedSubCategory from "@/components/LoadedSubCategory.vue";
import LoadedPathType from "@/components/LoadedPathType.vue";
import LoadedPath from "@/components/LoadedPath.vue";
import LoadedMarker from "@/components/LoadedMarker.vue";
import {store} from "@/store";
import ApiService from "@/services/api.service";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Dashboard
    },
    {
        path: '/shared/:link',
        name: 'shared',
        component: Dashboard,
        beforeEnter: async (to, from, next) => {
            const link = to.params.link;
            const route = await ApiService.getSharedInfoUrl(link + '');
            store.dispatch('defaults/setSharedLink', link);
            console.log(route);
            next(route);
        }
    },
    {
        path: '/category/:categoryId',
        name: 'Category',
        component: LoadedCategory,
        props: true

// }
    },
    {
        path: '/category/:categoryId/subcategory/:subcategoryId',
        name: 'SubCategory',
        component: LoadedSubCategory,
        props: true
    },
                          {
                              path: '/category/:categoryId/subcategory/:subcategoryId/poi/:poiId',
                              name: 'Point of interest',
                              component: LoadedMarker,
                              props: true

          },
    {
        path: '/pathtype/:pathtypeId',
        name: 'PathType',
        component: LoadedPathType,
        props: true
    },
                  {
                      path: '/pathtype/:pathtypeId/path/:pathId',
                      name: 'Path',
                      component: LoadedPath,
                      props: true
          }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
