
import {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLabel,
    IonToggle,
    IonItem,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonPage,
    IonContent, IonCol, IonGrid, IonRow, IonImg,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {chevronBackOutline, chevronDownOutline, locationOutline} from 'ionicons/icons';
    import {mapActions, mapGetters} from "vuex";
import {store} from "@/store";
import {useRoute} from "vue-router";
import SocialShare from "@/components/SocialShare.vue";
import ApiService from "@/services/api.service";
// import LanguageMenu from "@/components/LanguageMenu.vue";
import Footer from "@/components/Footer.vue";
import Refresher from "@/components/Refresher.vue";

    export default defineComponent({
        name: "LoadedPath",
        created(){
            // console.log('ionViewWillEnter',[]);
            ApiService.generateShareLink(this.route.path).then((result) => {
                if(result.success){
                    this.shareLink = result.sharelink;

                }else{
                    this.shareLink = null;
                }
            });
        },
        data(){
            const shareLink = null;
            let pathId: any;
            return{
                shareLink,
                pathId
            }
        },
        ionViewDidEnter(){
            const pathId: any  = this.route.params.pathId;
            this.pathId = pathId;
            // console.log('sdf',[pathtypeId,pathId,this.tip]);
            // console.log('did leave');

            if (!store.getters['paths/isPathSelected'](parseInt(pathId))) {
                store.dispatch('paths/togglePath', parseInt(pathId)
                );

            }else{
                store.dispatch('paths/focusPath',store.getters['paths/Path'](pathId));

            }
        },
        ionViewWillLeave(){
            // console.log('dfsdf',[this.pathId]);
            store.dispatch('paths/focusPath', {});
            store.dispatch('paths/zoomToPath',{});
            store.dispatch('paths/unfocusPath', store.getters['paths/Path'](this.pathId));
            // console.log('did leave');
        },
        methods:{
            ...mapActions({'TogglePath':'paths/togglePath'}),
            imagePath(image: any){
                return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
            },
            zoomToPath(){
                // console.log('hej',[this.loadedPath]);
                if(this.isDrawerFull){
                    console.log('half',[]);
                    store.dispatch('drawer/toggle','half')
                }
                store.dispatch('paths/zoomToPath',this.loadedPath);
            },
            zoomToPathStart(){
                // console.log('hej',[this.loadedPath]);
                store.dispatch('paths/zoomToPathStart', this.loadedPath);
            },
            zoomToPathEnd(){
                // console.log('hej',[this.loadedPath]);
                store.dispatch('paths/zoomToPathEnd', this.loadedPath);
            }
        },
        computed: {
            ...mapGetters({
                'isPathSelected': 'paths/isPathSelected',
                isDrawerSmall: "drawer/isDrawerSmall",
                isDrawerFull: "drawer/isDrawerFull",
            }),
            isDrawerActive() {
                return window.innerWidth < 850;
            },
            rootUrl() {
                return process.env.VUE_APP_STORAGE_URL;
            },
            loadedPathType() {
                const pathtypeId: any = this.route.params.pathtypeId;
                return store.getters['paths/PathType'](pathtypeId);
            },
            loadedPath() {
                const pathId: any = this.route.params.pathId;
                return store.getters['paths/Path'](pathId);
            },
        },
        components: {
            IonContent,
            IonPage,
            IonHeader,
            IonToolbar,
            IonButtons,
            IonButton,
            IonLabel,
            IonToggle,
            IonIcon,
            IonCard,
            IonCardHeader,
            IonCardTitle,
            IonCardSubtitle,
            IonCardContent,
            IonItem,
            SocialShare,
            IonCol,
            IonGrid,
            IonRow,
            IonImg,
            Refresher,
            // LanguageMenu,
            Footer
        },
        setup() {
            const route = useRoute();
            return {
                chevronBackOutline,
                locationOutline,
                chevronDownOutline,
route
            }
        },
        watch:{
        isDrawerSmall(state){
console.log('isDrawerSmall',[state]);
},
        isDrawerFull(state){
console.log('isDrawerFull' ,[state]);
},
            loadedPath(New, Old){

                if(Old) {
                    store.dispatch('paths/unfocusPath', store.getters['paths/Path'](parseInt(Old.id)));
                }
                if(New) {
                    store.dispatch('paths/focusPath', store.getters['paths/Path'](parseInt(New.id)));
                }
            }
        }
    });
