import ApiService from "@/services/api.service";
import {store} from "@/store/index";
import {StorageService} from "@/services/storage.service";

const state = {
    selectedPathTypes: [],
    selectedPaths: [],
    PathTypes: [],
    Paths: [],
    zoomToPath: {},
    zoomToPathStart: {},
    zoomToPathEnd: {},
    unfocusPath: {},
    focusPath: {}
};

const getters = {
    SelectedPaths: (state: { selectedPaths: any }) => {
        return state.selectedPaths;
    },
    SelectedPathTypes: (state: { selectedPathTypes: any }) => {
        return state.selectedPathTypes;
    },
    PathTypes: (state: { PathTypes: any }) => {
        return state.PathTypes;
    },
    isPathSelected: (state: { selectedPaths: any }) => {
        return (Id: any) => {
            return state.selectedPaths.includes(Id);
        };
    },
    isPathTypeSelected: (state: { selectedPathTypes: any }) => {
        return (Id: any) => {
            return state.selectedPathTypes.includes(Id);
        };
    },
    PathType: (state: { PathTypes: any }) => {
        return (Id: any) => { return state.PathTypes.find((pathtype: any) => pathtype.id == Id)  }
    },
    Paths: (state: { PathTypes: any }) => {
        return (Id: any) => {
            const pathType = state.PathTypes.find((pathtype: any) => pathtype.id == Id);

            function compare(a: any, b: any) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            }

            // return paths.sort(compare);
            if (pathType) {
                return pathType.paths.sort(compare);
            } else {
                return [];
            }
        }
    },
    Path: (state: { Paths: any }) => {
        return (Id: any) => { return state.Paths.find((path: any) => path.id == Id)  }
    },
    ZoomToPathStart: (state: {zoomToPathStart: any}) => {
        // console.log(state.zoomToPath);
        return state.zoomToPathStart;
    },
    ZoomToPathEnd: (state: {zoomToPathEnd: any}) => {
        // console.log(state.zoomToPath);
        return state.zoomToPathEnd;
    },
    ZoomToPath: (state: {zoomToPath: any}) => {
        // console.log(state.zoomToPath);
        return state.zoomToPath;
    },
    UnfocusPath: (state: {unfocusPath: any}) => {
        // console.log(state.unfocusPath);
        return state.unfocusPath;
    },
    FocusPath: (state: {focusPath: any}) => {
        // console.log(state.focusPath);
        return state.focusPath;
    }
};

const actions = {

    async init(context: any){
        // console.log('init paths');
        const pathtypes = await StorageService.getObject('pathtypes');
        if (pathtypes && pathtypes.length > 0) {
            context.commit('setPathTypes', pathtypes);
        }
        const data: any = await ApiService.getAllPathTypes();
        console.log('getAllPathTypes',data);
        if(data.success){
            context.commit('setPathTypes', data.pathtypes);
        }
    },
    async fetchSelected(context: any){
        let lastUpdated = parseInt(await StorageService.getObject('selected_paths_last_updated'));
        // console.log('lastupdated',[lastUpdated]);
        if(!lastUpdated || (new Date().getTime() - lastUpdated) < (process.env.VUE_APP_SELECTEDDURATION * 60 * 60 * 1000)) {
        const selectedPaths = await StorageService.getObject('selected_paths');
        if(selectedPaths && selectedPaths.length > 0){
            context.commit('setSelectedPaths',selectedPaths)
        }
        }
        lastUpdated = parseInt(await StorageService.getObject('selected_path_types_last_updated'));
        // console.log('lastupdated',[lastUpdated]);
        if((new Date().getTime() - lastUpdated) < (process.env.VUE_APP_SELECTEDDURATION * 60 * 60 * 1000)) {
        const selectedPathtypes = await StorageService.getObject('selected_path_types');
        if(selectedPathtypes && selectedPathtypes.length > 0){
            context.commit('setSelectedPathTypes',selectedPathtypes)
        }
        }
    },
    togglePathType({commit, state}: any, id: any){
        if(state.selectedPathTypes.includes(id)){
            commit("removeFromSelectedPathTypes", id);
        }else{
            commit("addToSelectedPathTypes", id);
        }
    },
    togglePath({commit, state}: any, id: any){
        if(state.selectedPaths.includes(id)){
            commit("removeFromSelectedPaths", id);
        }else{
            commit("addToSelectedPaths", id);
        }
    },
    setPathtypes(context: any, data: any){
        if(data && data.pathtypes){
            context.commit("setSelectedPaths", data.pathtypes);
        }
    },
    reset(context: any){
        context.commit('reset');
    },
    async check(state: any): Promise<any>{
        if(!state.paths || state.paths.length == 0){
           return await store.dispatch('paths/init');
        }else {
            return;
        }
    },
    setSelectedPaths(context: any, selectedPaths: any) {
        if(selectedPaths.length > 0){
            context.commit('setSelectedPaths',selectedPaths);
        }
    },
    setSelectedPathTypes(context: any, selectedPathTypes: any) {
        if(selectedPathTypes.length > 0){
            context.commit('setSelectedPathTypes',selectedPathTypes);
        }
    },
    zoomToPath(context: any, path: any) {
        context.commit('zoomToPath',path);
    },
    zoomToPathStart(context: any, path: any) {
        context.commit('zoomToPathStart',path);
    },
    zoomToPathEnd(context: any, path: any) {
        context.commit('zoomToPathEnd',path);
    },
    unfocusPath(context: any, path: any) {
        context.commit('unfocusPath',path);
    },
    focusPath(context: any, path: any) {
        context.commit('focusPath',path);
    },

};

const mutations = {
    addToSelectedPaths(state: {
        selectedPaths: any;
    }, Id: any) {
        // console.log('addToSelectedPaths',state.selectedPaths);
        state.selectedPaths.push(Id);
        // console.log('addToSelectedPaths',state.selectedPaths);
        StorageService.setObject('selected_paths',state.selectedPaths);
    },
    removeFromSelectedPaths(state: {
        selectedPaths: any;
    },Id: any) {
        // console.log('removeFromSelectedPaths',state.selectedPaths);
        const index = state.selectedPaths.indexOf(Id, 0);
        if (index > -1) {
            state.selectedPaths.splice(index, 1);
        }
       // console.log('removeFromSelectedPaths',state.selectedPaths);
                StorageService.setObject('selected_paths',state.selectedPaths);
    },
    addToSelectedPathTypes(state: {
        selectedPathTypes: any;
    }, Id: any) {
        // console.log('addToSelectedPathTypes',state.selectedPathTypes);
        state.selectedPathTypes.push(Id);
                StorageService.setObject('selected_path_types', state.selectedPathTypes);


        // console.log('addToSelectedPathTypes',state.selectedPathTypes);
    },
    removeFromSelectedPathTypes(state: {
        selectedPathTypes: any;
    },Id: any) {
        // console.log('removeFromSelectedPathTypes',state.selectedPathTypes);
        const index = state.selectedPathTypes.indexOf(Id, 0);
        if (index > -1) {
            state.selectedPathTypes.splice(index, 1);
        }
       // console.log('removeFromSelectedPathTypes',state.selectedPathTypes);
                        StorageService.setObject('selected_path_types', state.selectedPathTypes);

    },
    setPathTypes(state: { PathTypes: any; Paths: any }, pathtypes: any) {
        // console.log('setPathTypes',pathtypes);
        state.PathTypes = pathtypes;
        state.Paths = [];
        pathtypes.forEach((pathtype: any) => {
        pathtype.paths.forEach((path: any) => {
            state.Paths.push(path);
        });
        });
        StorageService.setObject('pathtypes',state.PathTypes);
    },
    reset(state: {selectedPaths: any; selectedPathTypes: any}){
        state.selectedPaths = [];
        state.selectedPathTypes = [];
        StorageService.removeItem('selected_paths');
        StorageService.removeItem('selected_paths_types');
    },
    setSelectedPaths(state: {selectedPaths: any}, selectedPaths: any){
        console.log('selectedPaths',[]);
        state.selectedPaths = selectedPaths;
        StorageService.setObject('selected_paths',state.selectedPaths);
    },
    setSelectedPathTypes(state: {selectedPathTypes: any}, selectedPathTypes: any){
        console.log('selectedPathTypes',[]);
        state.selectedPathTypes = selectedPathTypes;
                StorageService.setObject('selected_path_types', state.selectedPathTypes);

    },
    zoomToPathStart(state: {zoomToPathStart: any}, path: any){
        state.zoomToPathStart = path;
    },
    zoomToPathEnd(state: {zoomToPathEnd: any}, path: any){
        state.zoomToPathEnd = path;
    },
    zoomToPath(state: {zoomToPath: any}, path: any){
        state.zoomToPath = path;
        setTimeout(function() {
            state.zoomToPath = {};
        }, 100);
    },
    unfocusPath(state: {unfocusPath: any}, path: any){
        state.unfocusPath = path;
        setTimeout(function () {
            state.unfocusPath = undefined;
        }, 500);
    },
    focusPath(state: {focusPath: any}, path: any) {
        state.focusPath = path;
        setTimeout(function () {
            state.focusPath = undefined;
        }, 500);
    }
};
export const paths = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
