
import {IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonPage, IonSearchbar, IonThumbnail, IonToggle, IonToolbar} from '@ionic/vue';

import {defineComponent} from 'vue';
import {chevronBackOutline, chevronDownOutline} from 'ionicons/icons';
import {mapActions, mapGetters} from "vuex";
import {store} from "@/store";
import {useRoute} from "vue-router";
import Footer from "@/components/Footer.vue";
// import LanguageMenu from "@/components/LanguageMenu.vue";
import Refresher from "@/components/Refresher.vue";

export default defineComponent({
    name: "LoadedSubCategory",
    components: {
        Refresher,
        Footer,
        IonPage,
        IonContent,
        IonItem,
        IonLabel, IonIcon,
        IonItemDivider,
        IonToolbar,
        IonToggle,
        IonButton,
        IonButtons,
        IonImg,
        IonThumbnail,
        IonHeader,
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardContent,
        // LanguageMenu,
        IonSearchbar
    },
    data(){
        const searchInput = '';
        return {
            searchInput,
        }
    },
    setup() {
        const route = useRoute();
        return {
            route,
            chevronBackOutline,
            chevronDownOutline
        }
    },
    computed: {
        ...mapGetters({
            categories: "categories/Categories",
            isMarkerSelected: 'markers/isMarkerSelected',
            isSubCategorySelected: 'categories/isSubCategorySelected',
            isDrawerSmall: "drawer/isDrawerSmall",
            isDrawerFull: "drawer/isDrawerFull",
        }),
        isDrawerActive() {
            return window.innerWidth < 850;
        },
        loadedCategory() {
            const categoryId: any = this.route.params.categoryId;
            return store.getters['categories/Category'](categoryId);
        },
        loadedSubCategory() {
            const subcategoryId: any = this.route.params.subcategoryId;
            return store.getters['categories/SubCategory'](subcategoryId);
        },
        loadedMarkers() {
            const subcategoryId: any = this.route.params.subcategoryId;
            const markers = store.getters['categories/SubcategoryMarkers'](subcategoryId);
            if(this.searchInput != '') {
                return markers.filter((marker: any) => {
                    return marker.name.toLowerCase().match(this.searchInput.toLowerCase());
                });
            }
            return markers;
        }
    },
    methods: {
        ...mapActions({
            'ToggleMarker': 'markers/toggleMarker',
            'ToggleSubCategory': 'categories/toggleSubCategory'
        }),
        goToAndZoomIn(marker: any, path: string) {
            store.dispatch('markers/zoomToMarker', {marker: marker});
            const mark = store.getters['markers/Marker'](marker.parent_id, marker.guide_danmark_id);
            store.dispatch('markers/focusMarker', mark);
            this.$router.push(path);
        }
    }
});
