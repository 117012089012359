import ApiService from "@/services/api.service";
import {store} from "@/store/index";
import {StorageService} from "@/services/storage.service";

const state = {
    selectedCategories: [],
    selectedSubCategories: [],
    Categories: [],
    SubCategories: []
};

const getters = {
    SelectedCategories: (state: { selectedCategories: any }) => {
        return state.selectedCategories;
    },
    SelectedSubCategories: (state: { selectedSubCategories: any }) => {
        return state.selectedSubCategories;
    },
    Categories: (state: { Categories: any }) => {
        return state.Categories;
    },
    SubCategories: (state: { SubCategories: any }) => {
        return state.SubCategories;
    },
    Category: (state: { Categories: any }) => {
        return (GuideDanmarkId: any) => {
            return state.Categories.find((cat: any) => cat.guide_danmark_id == GuideDanmarkId)
        }
    },
    SubCategory: (state: { SubCategories: any }) => {
        return (GuideDanmarkId: any) => {
            return state.SubCategories.find((SubCat: any) => SubCat.guide_danmark_id == GuideDanmarkId)
        }
    },
    SubcategoryMarkers: (state: { SubCategories: any }) => {
        return (GuideDanmarkId: any) => {
            const subCategory = state.SubCategories.find((SubCat: any) => SubCat.guide_danmark_id == GuideDanmarkId);

            function compare(a: any, b: any) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            }

            // return paths.sort(compare);
            if (subCategory) {
                return subCategory.markers.sort(compare);
            } else {
                return [];
            }
        }
    },
    isCategorySelected: (state: { selectedCategories: any }) => {
        return (guideDanmarkId: any) => {
            return state.selectedCategories.includes(guideDanmarkId)
        };
    },
    isSubCategorySelected: (state: { selectedSubCategories: any }) => {
        return (guideDanmarkId: any) => {
            return state.selectedSubCategories.includes(guideDanmarkId)
        };
    }
};

const actions = {
    async init(context: any) {
        const categories = await StorageService.getObject('categories');
        if (categories && categories.length > 0) {
            context.commit('setCategories', categories);
        }
        const data: any = await ApiService.getAllCategories();
        if (data.success) {
            context.commit('setCategories', data.categories);
        }

    },
    async fetchSelected(context: any) {
        let lastUpdated = parseInt(await StorageService.getObject('selected_categories_last_updated'));
        // console.log('lastupdated', [lastUpdated]);
        if ((new Date().getTime() - lastUpdated) < (process.env.VUE_APP_SELECTEDDURATION * 60 * 60 * 1000)) {
            const selectedCategories = await StorageService.getObject('selected_categories');
            if (selectedCategories && selectedCategories.length > 0) {
                context.commit('setSelectedCategories', selectedCategories)
            }
        }
        lastUpdated = parseInt(await StorageService.getObject('selected_sub_categories_last_updated'));
        // console.log('lastupdated', [lastUpdated]);
        if ((new Date().getTime() - lastUpdated) < (process.env.VUE_APP_SELECTEDDURATION * 60 * 60 * 1000)) {
            const selectedSubCategories = await StorageService.getObject('selected_sub_categories');
            if (selectedSubCategories && selectedSubCategories.length > 0) {
                context.commit('setSelectedSubCategories', selectedSubCategories)
            }
        }
    },
    async fetchCategories({commit, state}: any) {
        if (state.Categories.length != 0) {
            return state.Categories;
        }
        const data: any = await ApiService.getAllCategories();
        if (data.success) {
            commit('setCategories', data.categories);
            return data.categories;
        }
    },
    toggleCategory({commit, state}: any, guideDanmarkId: any) {
        if (state.selectedCategories && state.selectedCategories.includes(guideDanmarkId)) {
            commit("removeFromSelectedCategories", guideDanmarkId);
        } else {
            commit("addToSelectedCategories", guideDanmarkId);
        }
    },
    toggleSubCategory({commit, state}: any, guideDanmarkId: any) {
        if (state.selectedSubCategories.includes(guideDanmarkId)) {
            commit("removeFromSelectedSubCategories", guideDanmarkId);
        } else {
            // console.log('toggleSubCategory',guideDanmarkId);
            commit("addToSelectedSubCategories", guideDanmarkId);
        }
    },
    addToSelectedSubCategories(context: any, guideDanmarkId: any) {
        if (guideDanmarkId) {
            context.commit("addToSelectedSubCategories", guideDanmarkId);
        }
    },
    removeFromSelectedSubCategories(context: any, guideDanmarkId: any) {
        if (guideDanmarkId) {
            context.commit("removeFromSelectedSubCategories", guideDanmarkId);
        }
    },
    addToSelectedCategories(context: any, data: any) {
        if (data.guideDanmarkId) {

            context.commit("addToSelectedCategories", data.guideDanmarkId);
        }
    },
    removeFromSelectedCategories(context: any, data: any) {
        if (data.guideDanmarkId) {
            context.commit("removeFromSelectedCategories", data.guideDanmarkId);
        }
    },
    setCategories(context: any, data: any) {
        if (data && data.categories) {
            context.commit("setSelectedSubCategories", data.categories);
        }
    },
    reset(context: any) {
        context.commit('reset');
    },
    async check(state: any): Promise<any> {
        if (!state.categories || state.categories.length == 0) {
            return await store.dispatch('categories/init');
        } else {
            return;
        }
    },
    setSelectedCategories(context: any, selectedCategories: any) {
        if (selectedCategories.length > 0) {
            context.commit('setSelectedCategories', selectedCategories);
        }
    },
    setSelectedSubCategories(context: any, selectedSubCategories: any) {
        if (selectedSubCategories.length > 0) {
            context.commit('setSelectedSubCategories', selectedSubCategories);
        }
    }

};

const mutations = {
    addToSelectedSubCategories(state: {
        selectedSubCategories: any;
    }, guideDanmarkId: any) {
        state.selectedSubCategories.push(guideDanmarkId);
        StorageService.setObject('selected_sub_categories', state.selectedSubCategories);

    },
    removeFromSelectedSubCategories(state: {
        selectedSubCategories: any;
    }, guideDanmarkId: any) {
        const index = state.selectedSubCategories.indexOf(guideDanmarkId, 0);
        if (index > -1) {
            state.selectedSubCategories.splice(index, 1);
        }
        // console.log('state.selectedSubCategories',state.selectedSubCategories);
        StorageService.setObject('selected_sub_categories', state.selectedSubCategories);

    },
    addToSelectedCategories(state: {
        selectedCategories: any;
    }, guideDanmarkId: any) {
        // const currentStatus = state.selectedCategories;
        // currentStatus.push(guideDanmarkId);
        state.selectedCategories.push(guideDanmarkId);
        // console.log('state.selectedCategories',state.selectedCategories);
        StorageService.setObject('selected_categories', state.selectedCategories);
    },
    removeFromSelectedCategories(state: {
        selectedCategories: any;
    }, guideDanmarkId: any) {
        const index = state.selectedCategories.indexOf(guideDanmarkId, 0);
        if (index > -1) {
            state.selectedCategories.splice(index, 1);
        }
        // console.log('state.selectedCategories',state.selectedCategories);
        StorageService.setObject('selected_categories', state.selectedCategories);
    },
    setCategories(state: { Categories: any; SubCategories: any }, categories: any) {
        state.Categories = categories;
        state.SubCategories = [];
        categories.forEach((cat: any) => {
            cat.sub_categories.forEach((subCat: any) => {
                state.SubCategories.push(subCat);
            });
        });
        StorageService.setObject('categories', state.Categories);
    },
    reset(state: { selectedCategories: any; selectedSubCategories: any }) {
        state.selectedSubCategories = [];
        state.selectedCategories = [];
        StorageService.removeItem('selected_categories');
        StorageService.removeItem('selected_sub_categories');
    },
    setSelectedCategories(state: { selectedCategories: any }, selectedCategories: any) {
        state.selectedCategories = selectedCategories;
        StorageService.setObject('selected_categories', state.selectedCategories);
    },
    setSelectedSubCategories(state: { selectedSubCategories: any }, selectedSubCategories: any) {
        state.selectedSubCategories = selectedSubCategories;
        StorageService.setObject('selected_sub_categories', state.selectedSubCategories);


    }
};

export const categories = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
