
    import {IonItem, IonLabel, IonList,
        // IonImg, IonThumbnail
    } from '@ionic/vue';
            import {defineComponent} from 'vue';
            import {mapActions, mapGetters} from "vuex";
    import {chevronBackOutline, chevronForwardOutline} from "ionicons/icons";
    import {useRouter} from "vue-router";
    export default defineComponent({
        name: "CategoriesList",
        components: {
            IonList,
            IonItem,
            IonLabel
        },
        setup() {
            const router = useRouter();
            return {
                chevronBackOutline,
                chevronForwardOutline,
                router
            }
        },
            computed: {
                ...mapGetters({
                    categories: "categories/Categories",
                    SelectedCategories: 'categories/SelectedCategories',
                    isCategorySelected: 'categories/isCategorySelected',
                    isDrawerSmall: "drawer/isDrawerSmall",
                }),
            },
        methods: {
            ...mapActions({
                'ToggleCategory':'categories/toggleCategory'
            }),
            imagePath(image: any){
                if(image != null) {
                    return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
                }
                return ''
            }
        }
    });
