
import {
    IonImg, IonLabel
} from '@ionic/vue';
import 'swiper/swiper.scss';

import "swiper/components/lazy/lazy.min.css"
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, {Autoplay, Lazy, Pagination} from 'swiper';
import {defineComponent} from 'vue';
import {Swiper, SwiperSlide} from "swiper/vue";
SwiperCore.use([Autoplay,Pagination, Lazy]);
export default defineComponent({
    name: "ImageGallery",
    components: { IonImg,
        Swiper,
        SwiperSlide,
        IonLabel
    },
    props:[
        'images'
    ],
    computed:{
        loadedImages(): any{
            this.images.forEach((image: any)=>{
                image.path = this.imagePath(image.Uri);
            });
            return this.images;
        }
    },
    methods: {
        imagePath(image: any){
            return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
        },

    }
});
