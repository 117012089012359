
    import {
        IonRefresher,
        IonRefresherContent
    } from '@ionic/vue';
    import {defineComponent} from 'vue';
    import {mapGetters} from "vuex";
    import {store} from "@/store";
    export default defineComponent({
        name: "Refresher",
        components: {
            IonRefresher,
            IonRefresherContent
        },
        emits: [
            'pullDrawer'
        ],
        computed: {
            ...mapGetters({
                isDrawerFull: "drawer/isDrawerFull",
            })
        },
        methods:{
            pullDrawer(event: any){
                if(this.isDrawerFull){
                    console.log('half',[]);
                    store.dispatch('drawer/toggle','half')
                }else{
                    console.log('small',[]);
                    store.dispatch('drawer/toggle','small')
                }
                    // this.$emit('pullDrawer');
                    event.target.complete();
            }
        }
    });
