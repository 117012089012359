// index.js
import en from './en.json';
import da from './da.json';
import de from './de.json';

export const defaultLocale = 'da';

export const languages = {
  en: en,
  da: da,
  de: de,
};
export const langs = [
    {iso2: 'gb', locale:'en', value: 'English'},
    {iso2: 'dk', locale:'da', value: 'Dansk'},
    {iso2: 'de', locale:'de', value: 'Deutsche'},
]
