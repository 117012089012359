
import {defineComponent} from 'vue';
import PathTypesList from "@/components/PathTypesList.vue";
import CategoriesList from "@/components/CategoriesList.vue";
import {mapGetters} from "vuex";
import {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonPage
} from "@ionic/vue";
// // import LanguageMenu from "@/components/LanguageMenu.vue";
import {store} from "@/store";
import {chevronDownOutline} from "ionicons/icons";
import Footer from "@/components/Footer.vue";
import Refresher from "@/components/Refresher.vue";

export default defineComponent({
    name: "Dashboard",
    components: {
        Refresher,
            IonPage,
        IonContent,
        PathTypesList,
        CategoriesList,
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        // LanguageMenu,
        Footer
    },
    setup() {
        return {chevronDownOutline}
    },

    created() {
        store.dispatch("dashboard/getDashboard")
    },

    methods: {
        pullDrawer(event: any) {
            if (this.isDrawerFull) {
                console.log('half', []);
                store.dispatch('drawer/toggle', 'half')
            } else {
                console.log('small', []);
                store.dispatch('drawer/toggle', 'small')
            }
            event.target.complete();
        },
        imagePath(image: any) {
            if (image != null) {
                return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
            }
            return ''
        }
    },
    computed: {
        ...mapGetters({
            frontText: "dashboard/getFrontImage",
            isDrawerSmall: "drawer/isDrawerSmall",
            isDrawerFull: "drawer/isDrawerFull",
            language: "defaults/Language"
        }),
        isDrawerActive() {
            return window.innerWidth < 850;
        }
    }

});
