import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_menu = _resolveComponent("ion-menu")
  const _component_ion_img = _resolveComponent("ion-img")
  const _component_ion_button = _resolveComponent("ion-button")
  const _component_ion_buttons = _resolveComponent("ion-buttons")
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")
  const _component_GoogleMap = _resolveComponent("GoogleMap")
  const _component_ContentDrawer = _resolveComponent("ContentDrawer")
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")
  const _component_ion_app = _resolveComponent("ion-app")

  return (_openBlock(), _createBlock(_component_ion_app, { scrollY: "false" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, { "content-id": "main-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            id: "appMenu",
            ref: "appMenu",
            "auto-hide": "false",
            "content-id": "main-container",
            "menu-id": "first",
            side: "start",
            "swipe-gesture": "false"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, { fullscreen: true }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_router_outlet, { fullscreen: true })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512),
          _createVNode(_component_ion_content, {
            id: "main-container",
            fullscreen: true,
            scrollY: "false"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { class: "main-toolbar" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, {
                    id: "logoButtons",
                    slot: "start",
                    class: (_ctx.isHomeLogoActive) ? 'home-logo-active' : 'home-logo-not-active'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHomeLogoActive = !_ctx.isHomeLogoActive))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_img, { src: _ctx.LogoImg }, null, 8, ["src"])
                        ]),
                        _: 1
                      }),
                      _createVNode("a", {
                        href: _ctx.env.homeUrl,
                        target: "_blank"
                      }, _toDisplayString(_ctx.$t('GOTOVISITSOENDERJYLLAND')), 9, ["href"])
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createVNode(_component_ion_buttons, {
                    id: "menuButtons",
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        "ion-only": "",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ToggleMenu()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_img, { src: _ctx.MenuImg }, null, 8, ["src"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (!_ctx.isDrawerActive)
                ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: 0,
                    id: "menu-button",
                    color: "light",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ToggleMenu()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: (_ctx.isMenuOpen) ? _ctx.chevronBackOutline : _ctx.chevronForwardOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_GoogleMap, {
                onOpenMenu: _ctx.openMenu,
                isHomeLogoActive: _ctx.isHomeLogoActive
              }, null, 8, ["onOpenMenu", "isHomeLogoActive"]),
              (_ctx.isDrawerActive)
                ? (_openBlock(), _createBlock(_component_ContentDrawer, {
                    key: 1,
                    ref: "Drawer"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_router_outlet, {
                        fullscreen: true,
                        id: "drawer-router-outlet"
                      })
                    ]),
                    _: 1
                  }, 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}