import {StorageService} from "@/services/storage.service";
import ApiService from "@/services/api.service";

const state = {
    Dashboard: []
}

const getters = {
    getFrontImage: (state: { Dashboard: any }) => {
        if(state.Dashboard != []){
            return state.Dashboard;
        } else {
            return false
        }
    },
}

const actions = {
    getDashboard(context: any,){
         ApiService.dashboardInfo().then((t) =>
            context.commit('addFrontText', t)

        )
    }
}

const mutations = {
    addFrontText(state: {Dashboard: any}, data: any) {
        state.Dashboard = data;
        StorageService.setObject('FrontImage', state.Dashboard);


    }
}

export const dashboard = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};