
import {defineComponent, onMounted, ref} from 'vue';
import {closeOutline} from 'ionicons/icons';
import {
    createGesture,
    IonIcon,
    IonFabButton, IonCol, IonRow
} from '@ionic/vue';
import {store} from "@/store";
import router from "@/router";
import {mapGetters} from "vuex";

export default defineComponent({
    name: "ContentDrawer",
    computed: {
        ...mapGetters({
            'toggle': 'drawer/toggle'
        })
    },
    methods: {
        toggleDrawer(toSize: any) {
            console.log('hey', [toSize]);
            switch (toSize) {
                case 'small':
                    this.goSmall();
                    break;
                case 'half':
                    this.goHalf();
                    break;
                case 'full':
                    this.goFull();
                    break;
                case undefined:
                    if (!this.small && !this.half && !this.full) {
                        this.goSmall();
                    }
                    break;
            }
            if (!this.small && !this.half && !this.full) {
                this.goSmall();
            }
        },
        openDrawer() {
            if (!this.half && !this.full) {
                this.goSmall();
            } else if (this.small) {
                this.goHalf();
            } else if (this.half) {
                this.goFull();
            }
        },
        closeDrawer() {
            this.goClose();
        },
    },
    watch: {
        small() {
            store.dispatch('drawer/setIsDrawerSmall', this.small);
        },
        full() {
            store.dispatch('drawer/setIsDrawerFull', this.full);
        },
        toggle(to, from) {
            console.log(to)
            console.log(from)
            if (to === 'half') {
                this.goHalf();
            }
            if (to === 'small') {
                this.goSmall();
            }
        }
    },
    mounted() {
        window.onresize = () => {
            this.openHeight = window.innerHeight;
        }
        this.goHalf();

    },
    components: {
        IonIcon,
        IonFabButton,
        IonCol,
        IonRow,
    },
    setup() {
        let vvh = document.documentElement.clientHeight;
        const drawer = ref();
        const routeer = router;
        const drawerPull = ref();
        const drawerContent = ref();
        const pullContentGesture: any = ref();
        const small = ref(false);
        const half = ref(false);
        const full = ref(false);
        const openHeight = window.innerHeight;

        const goSmall = () => {
            drawer.value.style.transition = '.4s ease-out';
            drawerContent.value.style.transition = '.4s ease-out';
            drawer.value.style.transform = 'translateY(0px)';
            drawer.value.style.bottom = 'calc(100% - (('+vvh+'px * 0.01) * 89))';
            // drawerContent.value.style.height = (drawer.value.clientHeight - 36) + 'px';
            full.value = false;
            half.value = false;
            small.value = true;
            store.dispatch('drawer/setIsDrawerSmall', small.value);
            store.dispatch('drawer/setIsDrawerFull', full.value);
            console.log(pullContentGesture.value);
            if (pullContentGesture.value) {
                pullContentGesture.value.enable(true);
            }
        };
        const goHalf = () => {
            console.log('vvh go Half',[vvh]);

            drawer.value.style.transition = '.4s ease-out';
            drawerContent.value.style.transition = '.4s ease-out';
            drawer.value.style.transform = 'translateY(0px)';
            drawer.value.style.bottom = '-50vh';
            // drawer.value.style.bottom = 'calc('+vvh+' * -50vh);';
            // drawerContent.value.style.height = (drawer.value.clientHeight - 36) + 'px';
            full.value = false;
            small.value = false;
            half.value = true;
            store.dispatch('drawer/setIsDrawerSmall', small.value);
            store.dispatch('drawer/setIsDrawerFull', full.value);
            console.log(pullContentGesture.value);
            if (pullContentGesture.value) {
                pullContentGesture.value.enable(true);
            }
        };
        const goFull = () => {
            console.log('vvh go Full', [vvh]);

            drawer.value.style.transition = '.2s ease-out';
            drawerContent.value.style.transition = '.2s ease-out';
            drawer.value.style.transform = 'translateY(0px)';
            drawer.value.style.bottom = '-0vh';
            // drawerContent.value.style.height = (drawer.value.clientHeight - 36) + 'px';
            full.value = true;
            half.value = false;
            small.value = false;
            store.dispatch('drawer/setIsDrawerSmall', small.value);
            store.dispatch('drawer/setIsDrawerFull', full.value);
            console.log(pullContentGesture.value);
            if (pullContentGesture.value) {
                pullContentGesture.value.enable(false);
            }
        };
        const goClose = () => {
            drawer.value.style.transition = '.2s ease-out';
            drawerContent.value.style.transition = '.2s ease-out';
            drawer.value.style.transform = 'translateY(0px)';
            drawer.value.style.bottom = '-100vh';
            drawerContent.value.style.height = '0px';
            full.value = false;
            half.value = false;
            small.value = false;
            store.dispatch('drawer/setIsDrawerSmall', small.value);
            store.dispatch('drawer/setIsDrawerFull', full.value);
            console.log(pullContentGesture.value);
            if (pullContentGesture.value) {
                pullContentGesture.value.enable(true);
            }
        };
        onMounted(() => {
            console.log('vvh',[vvh]);
            window.addEventListener('resize', function () {
                vvh = window.innerHeight/100;
                console.log('vvh',[vvh]);
            });
            window.addEventListener('orientationchange', function () {
                vvh = window.innerHeight/100;
                console.log('vvh',[vvh]);
            });
            const gesture = createGesture({
                el: drawerPull.value,
                threshold: 1,
                gestureName: 'swipe',
                direction: 'y',
                onMove: ev => {
                    // console.log('onMove', ev);
                    if (ev.deltaY < -openHeight) return;
                    drawer.value.style.transform = 'translateY(' + ev.deltaY + 'px)';
                },
                onEnd: ev => {
                    if (ev.deltaY < -10 && small.value || ev.deltaY > 10 && full.value) {
                        goHalf();
                    } else if (ev.deltaY < -10 && half.value) {
                        goFull();
                    } else if ((ev.deltaY > 10 && half.value) || (ev.deltaY > 1 && small.value)) {
                        goSmall();
                    }
                }
            });
            gesture.enable(true);
            pullContentGesture.value = createGesture({
                el: drawerContent.value,
                threshold: 15,
                gestureName: 'swipe',
                direction: 'y',
                onMove: ev => {
                    // console.log('onMove', ev);
                    if (ev.deltaY < -openHeight) return;
                    drawer.value.style.transform = 'translateY(' + ev.deltaY + 'px)';
                },
                onEnd: ev => {
                    if (ev.deltaY < -50 && small.value || ev.deltaY > 50 && full.value) {
                        goHalf();
                    } else if (ev.deltaY < -50 && half.value) {
                        goFull();
                    } else if (ev.deltaY > 50 && half.value) {
                        goSmall();
                    }
                }
            });
            console.log(pullContentGesture.value);
            if (pullContentGesture.value) {
                pullContentGesture.value.enable(true);
            }
            if (routeer.currentRoute.value.name != 'Home') {
                goHalf();
            }
        });
        return {
            half,
            full,
            small,
            drawer,
            drawerPull,
            drawerContent,
            openHeight,
            closeOutline,
            goHalf,
            goSmall,
            goFull,
            goClose,
            pullContentGesture,
            vvh
        }
    }
});
