import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { store } from "@/store";
import i18n from '@/i18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';


/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import ApiService from "@/services/api.service";

// Languages
// const messages = Object.assign(languages);
import './registerServiceWorker'
import {StorageService} from "@/services/storage.service";


defineCustomElements(window);

const app = createApp(App)
  .use(IonicVue)
  .use(router)
      .use(store)
    .use(i18n);
ApiService.init(process.env.VUE_APP_ROOT_API);


async function getLanguage () {
    await StorageService.getObject('selected_language').then((language) => {
        if (language && language != undefined && language != 'undefined') {
            store.dispatch('defaults/setLanguage', language);
        } else {
            store.dispatch('defaults/setLanguage');
        }
    });
}
getLanguage();
router.isReady().then(() => {
    app.mount('#app');
  ApiService.monitorNetworkChange();
  ApiService.setHeader();
});
