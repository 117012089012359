
import {
    IonItem,
    IonLabel,
    IonContent,
    IonIcon,
    IonHeader,
    IonButtons,
    IonItemDivider,
    IonToggle,
    IonToolbar,
    IonButton,
    IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonPage,
} from '@ionic/vue';
import {chevronBackOutline, chevronForwardOutline, chevronDownOutline} from 'ionicons/icons';

import {useRoute} from 'vue-router';

import {defineComponent} from 'vue';
import {mapActions, mapGetters} from "vuex";
import {store} from "@/store";
import Footer from "@/components/Footer.vue";
// import LanguageMenu from "@/components/LanguageMenu.vue";
import Refresher from "@/components/Refresher.vue";

export default defineComponent({
    name: "LoadedCategory",
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonIcon,
        IonHeader,
        IonButtons,
        IonItemDivider,
        IonToolbar,
        IonToggle,
        IonButton,
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardContent,
        // LanguageMenu,
        Refresher,
        Footer
    },
    setup() {
        // console.log(this.$refs.isDrawerSmall);
        const route = useRoute();
        // const loadedCategory = store.getters['categories/Category'](categoryId);
        // console.log(loadedCategory);
        return {route, chevronBackOutline, chevronForwardOutline, chevronDownOutline}
    },
    methods: {
        ...mapActions({
            'ToggleCategory': 'categories/toggleCategory',
            'ToggleSubCategory': 'categories/toggleSubCategory'
        }),
    },
    computed: {
        ...mapGetters({
            categories: "categories/Categories",
            isSubCategorySelected: 'categories/isSubCategorySelected',
            isCategorySelected: 'categories/isCategorySelected',
            isDrawerSmall: "drawer/isDrawerSmall",
            isDrawerFull: "drawer/isDrawerFull",
        }),
        loadedCategory() {
            const categoryId: any = this.route.params.categoryId;
            return store.getters['categories/Category'](categoryId);
        },
        isDrawerActive() {
            return window.innerWidth < 850;
        }
    }
});
