
import {
    IonLabel,
    IonToggle,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonImg,
    IonToolbar,
    IonItem,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonPage, IonContent, IonGrid, IonRow, IonCol
} from '@ionic/vue';
    import {defineComponent} from 'vue';
import {chevronBackOutline, chevronDownOutline, locationOutline} from 'ionicons/icons';
import {mapActions, mapGetters} from "vuex";
    import ImageGallery from "@/components/ImageGallery.vue";
    import {store} from "@/store";
import Footer from "@/components/Footer.vue";
import {useRoute} from "vue-router";
// import LanguageMenu from "@/components/LanguageMenu.vue";
import SocialShare from "@/components/SocialShare.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import Refresher from "@/components/Refresher.vue";

    export default defineComponent({
        name: "LoadedMarker",
        components: {
            Refresher,
            SocialShare,
            Footer,
            IonContent,
            IonPage,
            IonLabel,
            IonHeader,
            IonButtons,
            IonButton,
            IonToggle,
            IonIcon,
            IonImg,
            IonToolbar,
            IonItem,
            ImageGallery,
            IonCard,
            IonCardHeader,
            IonCardTitle,
            IonCardSubtitle,
            IonCardContent,
            // LanguageMenu,
            IonGrid,
            IonRow,
            IonCol,
        },
        data(){
            let subcategoryId: any;
            let poiId: any;
            return{
                subcategoryId,
                poiId
            }
        },
        ionViewDidEnter(){
            const subcategoryId: any = this.route.params.subcategoryId;
            const poiId: any  = this.route.params.poiId;
            this.subcategoryId = subcategoryId;
            this.poiId = poiId;
const mark = store.getters['markers/Marker'](subcategoryId,poiId);
                // console.log('will enter');

            // if (!store.getters['markers/isMarkerSelected'](parseInt(subcategoryId), parseInt(poiId))) {
            //     console.log('sdf',);
            //     store.dispatch('markers/toggleMarker', {
            //         subCategoryGuideDanmarkId: parseInt(subcategoryId),
            //         guideDanmarkId: parseInt(poiId)
            //     });
            // }else{
            if(mark) {
                store.dispatch('markers/focusMarker', mark);
            }
            // }
            },
        ionViewDidLeave() {
            const mark = store.getters['markers/Marker'](this.subcategoryId, this.poiId);
            store.dispatch('markers/unfocusMarker', mark);
        },
        setup() {
            const route = useRoute();
            const ToggleMarker = (marker: any) => {
                // const poiId: any  = route.params.poiId;
                // console.log('poiId', poiId);
                //     if (store.getters['markers/isMarkerSelected'](parseInt(marker.category_id), parseInt(marker.guide_danmark_id)) && poiId == marker.guide_danmark_id) {
                //
                //     }
                // // console.log('marker', marker);
                // if(store.getters['categories/isSubCategorySelected'](marker.category_id)){
                //     store.dispatch('markers/unfocusMarker', store.getters['markers/Marker'](marker.category_id,marker.guide_danmark_id));
                // }else {
                    store.dispatch('markers/toggleMarker', {subCategoryGuideDanmarkId: marker.category_id, guideDanmarkId: marker.guide_danmark_id});
                // }

            };
            // const { categoryId } = route.params;
            // const { subcategoryId } = route.params;
            // const { poiId } = route.params;
            return {
                route,
                chevronBackOutline,
                ToggleMarker,
                locationOutline,
                chevronDownOutline
            }
        },
        computed: {
            ...mapGetters({
                isMarkerSelected: 'markers/isMarkerSelected',
                isSubCategorySelected: 'categories/isSubCategorySelected',
                isDrawerSmall: "drawer/isDrawerSmall",
                isDrawerFull: "drawer/isDrawerFull",
                markers: 'markers/Markers',
            }),
            isDrawerActive() {
                return window.innerWidth < 850;
            },
            map() {
                return GoogleMap.map;
            },
            SubCategory() {
                const subcategoryId: any = this.route.params.subcategoryId;
                return store.getters['categories/SubCategory'](subcategoryId);
            },
            loadedMarker() {
                const subcategoryId: any = this.route.params.subcategoryId;
                const poiId: any = this.route.params.poiId;
                const mark = store.getters['markers/Marker'](subcategoryId,poiId);
                if(mark) {
                    // console.log('mark1',[mark]);
                    mark.selected = store.getters['markers/isMarkerSelected'](subcategoryId, poiId);
                }
                // console.log('mark',[mark]);
                return mark
            }
        },
        methods: {
            ...mapActions({
                'ToggleSubCategory': 'categories/toggleSubCategory'
            }),
            imagePath(image: any){
                return (image.includes('//')) ? image : process.env.VUE_APP_STORAGE_URL + image;
            },
            zoomToMarker(){
                if(this.isDrawerFull){
                    console.log('half',[]);
                    store.dispatch('drawer/toggle','half')
                }

                store.dispatch('markers/zoomToMarker',{marker: this.loadedMarker});

            },
            pullDrawer(event: any){
                if(this.isDrawerFull){
                    console.log('half',[]);
                    store.dispatch('drawer/toggle','half')
                }else{
                    console.log('small',[]);
                    store.dispatch('drawer/toggle','small')
                }
                event.target.complete();
            },
        },
        watch:{
            isDrawerSmall(state){
                console.log('isDrawerSmall',[state]);
            },
            isDrawerFull(state){
                console.log('isDrawerFull' ,[state]);
            },
            loadedMarker(New, Old){

                if(Old) {
                    store.dispatch('markers/unfocusMarker', store.getters['markers/Marker'](parseInt(Old.category_id), parseInt(Old.guide_danmark_id)));
                }
                if(New) {
                    New.selected = store.getters['markers/isMarkerSelected'](parseInt(New.category_id), parseInt(New.guide_danmark_id));
                        store.dispatch('markers/focusMarker', New);
                }
            }
        }
    });
